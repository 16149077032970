<template>
  <ion-page>

    <main-header />

    <ion-content :fullscreen="true">
      
    <ion-grid style="margin-bottom: 20px;">
        <ion-row>
            <ion-col size="12" v-for="coupon in store.state.coupons" :key="coupon.id" style="position: relative">
                <ion-icon size="large" :icon="gift" style="border-radius: 50px; background: white; box-shadow: 0px 0px 5px #00000080; padding: 3px; color:#ffd700; position: absolute; right: 20px; top: 10px; font-size: 20px; z-index: 9"></ion-icon>
                <div style="border-radius: 20px; box-shadow: 0px 0px 5px #00000080; padding-bottom: 1px">
                    <img style="border-radius: 20px" :src="coupon.image"/>
                    <div style="text-align: center; color: #1f2e60">{{switchLang(coupon.descriptionEn, coupon.descriptionAr)}}</div>
                    <div style="text-align: center; color: #1f2e60; padding: 5px; margin: 5px; background: #b9fd99; font-weight: 700; border: 3px solid #1f2e60; border-radius: 20px">{{switchLang('Code: ' + coupon.code, coupon.code + ':كود')}}</div>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, computed } from 'vue'
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/vue'
import { cart, gift } from 'ionicons/icons';

import MainHeader from '@/views/Admin/Components/MainHeader.vue'

import store from '@/store/index';

const en = computed(() => s.lang == 'en')

const switchLang = (en, ar) => {
  if(s.lang == 'en')
  { return en } 
  else if(s.lang == 'ar')
  { return ar }
}
  
</script>