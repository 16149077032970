<template>
  <ion-page>
    <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
      <ion-toolbar style="--background: #ffffff00">
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
          <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
          <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #1f2e60">{{ en ? 'Clients' : 'العملاء' }}</div></ion-title>
      </ion-toolbar>
    </ion-header>
      
    <ion-content :fullscreen="true">
      
      <div>
        <ion-grid style="margin-bottom: 20px;">
          <UserCard v-for="u in users" :key="u.id" :user="u"/>
        </ion-grid>
      </div>

    </ion-content>
  </ion-page>
</template>
    
<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, onMounted, computed } from 'vue'
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonGrid } from '@ionic/vue'
// import { cart } from 'ionicons/icons';
import { useRoute } from 'vue-router'

import UserCard from './Components/UserCard.vue'

import store from '@/store/index';
import router from '@/router/index';

      const switchLang = (en, ar) => {
        if(s.lang == 'en')
        { return en } 
        else if(s.lang == 'ar')
        { return ar }
      }

      const en = computed(() => s.lang == 'en')

      const route = useRoute();

      onMounted(() => {
        store.dispatch('getSupplierOrders')
        store.dispatch('getSupplierProductsVersions')
      })

      const users = computed(() => {

        let result = {}
        store.state.orders.map(el=>{
          result[el.client.id] = el.client
        })
        return Object.values(result)

      })

      console.log(users.value)

</script>