<template>
    <ion-page  v-if="user">
      <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
        <ion-toolbar style="--background: #ffffff00">
          <ion-buttons slot="start">
            <ion-back-button text="" v-if="!store.state.editUserFormOpened" color="primary" :defaultHref="'/admin/' + route.path.split('/')[2]"></ion-back-button>
          </ion-buttons>
            <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px; margin-top: 20px" src="@/../public/assets/logos/101_logo_ideas_2.png"/></ion-title> -->
            <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #1f2e60">Storz</div></ion-title>
          <ion-buttons slot="end" style="position: relative">
            <!-- <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 0px; right: 30px; padding: 0px 5px; z-index: 9; background: lightgreen; height: 20px; border-radius: 50px; text-align: center; font-size: 16px;">{{ store.getters.getCartItemsCount }}</div>
          <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 20px; right: 30px; padding: 0px 5px; z-index: 9; background: lightblue; height: 20px; border-radius: 50px; text-align: center; font-size: 16px"><span style="color:white">KD</span>{{ store.getters.getCartTotal }}</div> -->
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      
      <ion-content :fullscreen="true">
  
          <ion-grid style="margin-bottom: 20px" :dir="s.lang == 'ar' ? 'rtl' : 'ltr'">
            <ion-row>
              <ion-col>
                <div v-if="!store.state.editUserFormOpened" style="margin: 5px; padding: 5px; box-shadow: 0px 0px 5px #00000080; border-radius: 20px">
                  <div style="display: flex; justify-content: space-between; padding: 10px">
                    <!-- <ion-icon @click="deleteUser()" size="large" :icon="trash" color="danger"></ion-icon> -->
                    <ion-icon @click="editUser()" size="large" :icon="create" color="success"></ion-icon>
                  </div>
                  <div style="padding: 5px">
                    <img v-if="user.logo && user.logo.split('storage')[1]" style="border-radius: 20px" :src="user.logo">
                  </div>
                  <div style="text-align: center">
                    <div style="font-weight: 700; font-size: 32px">{{switchLang(user.name_en, user.name_ar)}}</div>
                    <div>{{switchLang(user.description_en, user.description_ar)}}</div>
  
                  </div>
                </div>
  
                <UserForm v-if="store.state.editUserFormOpened" :entryType="{process: 'Edit'}"/>
  
              </ion-col>
            </ion-row>
          </ion-grid>
  
      </ion-content>
    </ion-page>
  </template>
  
<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, computed, ref, onMounted } from 'vue'
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { trash, create } from 'ionicons/icons';
import { useRoute } from 'vue-router'
import store from '@/store/index';
import router from '@/router/index';
import UserForm from '@/views/Admin/Pages/UserForm.vue'
import axios from 'axios'
  
onMounted(() => {
  store.dispatch('getAdminSuppliers')
  store.dispatch('getAdminClients')
})

const switchLang = (en, ar) => {
  if(s.lang == 'en')
  { return en } 
  else if(s.lang == 'ar')
  { return ar }
}

const route = useRoute()

const user = computed(() => {
  if(route.path.split('/')[2] == 'suppliers'){
    return store.state.suppliers.find(p => p.id == parseInt(route.params.id, 10))
  } else if(route.path.split('/')[2] == 'clients'){
    return store.state.clients.find(p => p.id == parseInt(route.params.id, 10))
  }else if(route.path.split('/')[2] == 'users'){
    return store.state.adminUsers.find(p => p.id == parseInt(route.params.id, 10))
  } else {
    return {}
  }
})

const addToCart = (user) => {
    store.state.user.cart.push(user)
    localStorage.setItem('cart', JSON.stringify(store.state.user.cart))
}

const editUser = () => {
  store.state.userToEdit = {...user.value}
  store.state.userToEditImage = store.state.userToEdit.logo
  store.state.editUserFormOpened = true
}

const deleteUser = () => {
  axios({
      method: 'delete',
      url:`${store.state.api}users/${route.params.id}`,
      headers: {
      'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
      'Content-Type': 'application/x-www-form-urlencoded'
      // 'Content-Type': 'multipart/form-data'
      }
  }).then( () => {
      store.state.toastMessage = 'User Deleted!'
      store.state.toastColor = 'success'
      store.dispatch('openToast')
      store.dispatch('getUsers')

      router.push({name:'admin users'})

      // location.reload()
      
  }).catch((errors) => {
      store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
      store.state.toastColor = 'danger'
      store.dispatch('openToast')
  })
}

</script>

<style scoped>

</style>