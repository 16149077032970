<template>
    <ion-col size="6" sizeSm="4" sizeMd="3" sizeLg="2" :class="catPopClass" style="padding: 10px">
        <div style="cursor: pointer; height: 100%; box-shadow: 0px 0px 5px #c2c2c2; border-radius:20px; font-size: 18px" class="btn btnFkt" :dir="s.lang == 'ar' ? 'rtl' : 'ltr'">
            <img style="border-radius: 20px; height: 150px; width: 100%; object-fit: cover; overflow: hidden" :src="category.image">
            <div style="padding: 5px 15px">
                <div style="color: black; width:100%;">{{en ? category.name_en : category.name_ar}}</div>
                <div style="">{{ category.price}} {{ en ? 'KD' : 'د.ك.' }}</div>
            </div>
        </div>
    </ion-col>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { computed, ref, defineProps } from 'vue'
import { IonCol } from '@ionic/vue';
import store from '@/store/index';
import router from '@/router/index';

    const props = defineProps({
        category: Object,
        path: String
    })

    const en = computed(() => s.lang == 'en')
    
    const catPopClass = ref()

    if(store.state.intro){
        setTimeout(() => {
            store.state.intro = false
            catPopClass.value = 'popOver'
        }, 1000)
    } else {
            catPopClass.value = 'popOver'
    }


    const goCategory = (c) => {
        router.push(props.path)
    }

</script>

<style scoped>

.popOver {
    -webkit-animation: bummer 0.5s;
    animation: bummer 0.5s;
    -webkit-transform: scale(0,0); 
    transform: scale(0,0);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; /* Add this so that your modal doesn't 
                                        close after the animation completes */
}

@-webkit-keyframes bummer {
    100% {
        -webkit-transform: scale(1,1); 
    }
}

@keyframes bummer {
    100% {
        transform: scale(1,1); 
    }
    }

.btnFkt:hover{
  transition-duration: 0.1s;
  box-shadow: 0px 0px 5px #00000080;
  scale: 1.05;
}
.btnFkt:active{
  transition-duration: 0.1s;
  box-shadow: 0px 0px 5px #00000080;
  scale: 0.9;
}

</style>