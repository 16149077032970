<template>
  <ion-page>

    <main-header :title="{en:s.state.product.name_en,ar: s.state.product.name_ar}"/>

    <ion-content :fullscreen="true">

        <ion-grid style="margin-bottom: 20px" :dir="s.lang == 'ar' ? 'rtl' : 'ltr'">
          <ion-row>
            <ion-col size="12" sizeMd="6">
              <div style="margin: 5px; padding: 5px; box-shadow: 0px 0px 5px #00000080; border-radius: 20px">
                <div style="padding: 5px">
                  <img style="border-radius: 20px" :src="s.state.product.image">
                </div>

              </div>

            </ion-col>
            <ion-col size="12" sizeMd="6" style="padding: 20px">
              <ion-row>
                <div style="width: 100%">
                  <div style="font-weight: 700">{{switchLang(s.state.product.name_en, s.state.product.name_ar)}}</div>
                  <div>{{switchLang(s.state.product.description_en, s.state.product.description_ar)}}</div>
                </div>
              </ion-row>
              <ion-row>
                <!-- <div style="display: flex; flex-wrap: wrap ;justify-content: space-around; align-items: center; width: 100%">
                  <div style="margin: 20px">{{ switchLang('Price', 'السعر') }} {{s.state.product.price}} {{switchLang('KD', 'د.ك.')}}</div>
                </div> -->
                <ion-col>
                  <div style="margin: 20px">{{ switchLang('Price', 'السعر') }} {{s.state.product.price}} {{switchLang('KD', 'د.ك.')}}</div>
                </ion-col>
                <ion-col style="display: flex; align-items: center">
                  <div>{{ switchLang('Quantity', 'الكميه') }}</div>
                  <div style="width: 100px">
                    <ion-select v-model="quantity">
                      <ion-select-option v-for="i in 10" :key="i" :value="i" style="text-align: center">{{ i }}</ion-select-option>
                    </ion-select>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row>
                <div style="display: flex; flex-wrap: wrap ;justify-content: space-around; align-items: center; width: 100%">
                  <!-- <div style="cursor: pointer; display: flex;">
                      <div style="cursor: pointer; padding: 5px; display: flex; align-items: center">
                        <div @click="changeQuantity(false)" style="font-size: 50px; line-height: 10px; color: #000098; margin-top: 5px">-</div>
                      </div>
                      <ion-input @ionChange="updateCart()" type="number" style="width: 50px; font-size: 25px; border-radius: 50px; text-align: center; color: #1f2e60; margin: 5px 5px 0px 0px; --padding-start: 0px;" v-model="quantity" ></ion-input>
                      <div style="cursor: pointer; padding: 5px; display: flex; align-items: center">
                        <div @click="changeQuantity(true)" style="font-size: 40px; line-height: 10px; color: #000098">+</div>
                      </div>
                  </div> -->
                  <div class="btn imgHvr" @click="addToCart()" style="cursor: pointer; width: 100%; background: #336666; padding: 10px 20px 5px 20px; margin: 10px; border-radius: 50px; text-align: center;  color: white; display: flex; align-content: center; justify-content: center">
                    <div>{{ quantity * s.state.product.price}}</div>
                    <ion-icon size="large" :icon="cart"></ion-icon>
                  </div>
                </div>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import MainHeader from '@/views/Admin/Components/MainHeader.vue'

import { defineComponent, computed, ref, onMounted } from 'vue'
import { IonBackButton, IonInput, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon, IonSelect, IonSelectOption } from '@ionic/vue';
import { trash, create, cart } from 'ionicons/icons';
import { useRoute } from 'vue-router'
const route = useRoute()

import store from '@/store/index';
import router from '@/router/index';
import ProductForm from '@/views/Admin/Pages/ProductForm.vue'
import axios from 'axios'

const switchLang = (en, ar) => {
  if(s.lang == 'en')
  { return en } 
  else if(s.lang == 'ar')
  { return ar }
}

onMounted(() => {
  s.getClientProduct(route.params.id)
})

const quantity = ref(1)

const changeQuantity = (add) => {
  if(add){
    quantity.value++ 
  }else if(!add && quantity.value > 1){
    quantity.value--
  }
}

const updateCart = () => {
    if(quantity.value == 0 || quantity.value == '' || isNaN(quantity.value)){
      quantity.value = 1
    }
    // store.state.user.cart[props.s.state.product.supplier.id].items.find((cartItem) => cartItem.s.state.product.id == props.s.state.product.id).quantity = quantity.value
    localStorage.setItem('cart', JSON.stringify(store.state.user.cart))
  
}

const addToCart = () => {
  const cartItem = s.state.user.cart[s.state.product.id] ? s.state.user.cart[s.state.product.id] : null
  
  if(cartItem){
    cartItem.quantity = cartItem.quantity + quantity.value
  } else {
    s.state.user.cart[s.state.product.id] = {
      item: s.state.product,
      quantity: quantity.value,
    }
  }
  
}

</script>

<style scoped>

.imgHvr:hover{
  transition-duration: 0.1s;
  box-shadow: 0px 0px 5px #00000080;
  scale: 1.05;
}
.imgHvr:active{
  transition-duration: 0.1s;
  box-shadow: 0px 0px 5px #00000080;
  scale: 0.9;
}

</style>