import store from '@/store';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Loading from '@/views/Loading.vue'

// // // Auth // // // 
import ContactUs from '@/views/General/ContactUs.vue'
import privacyPolicy from '@/views/General/PrivacyPolicy.vue'

// // // Auth // // // 
import Login from '@/views/Auth/Login.vue'
import Register from '@/views/Auth/Register.vue'

// // // Admin // // // 
import AdminDashboard from '@/views/Admin/Dashboard.vue'
import AdminCategories from '@/views/Admin/Categories.vue'
import AdminCategory from '@/views/Admin/Category.vue'
import AdminSubcategories from '@/views/Admin/Pages/Subcategories.vue'
import AdminProductsVersions from '@/views/Admin/Pages/ProductsVersions.vue'
import AdminOrders from '@/views/Admin/Orders.vue'
import AdminProducts from '@/views/Admin/AdminProducts.vue'
import AdminUsers from '@/views/Admin/Users.vue'
import JoinRequests from '@/views/Admin/JoinRequests.vue'
import AdminUser from '@/views/Admin/Pages/User.vue'
import AdminProduct from '@/views/Admin/Pages/Product.vue'
import AdminOrder from '@/views/Admin/Pages/Order.vue'

// // // Supplier // // // 
import SupplierClients from '@/views/Supplier/Clients.vue'
import SupplierClient from '@/views/Supplier/Pages/User.vue'
import SupplierDashboard from '@/views/Supplier/Dashboard.vue'
import SupplierOrders from '@/views/Supplier/Orders.vue'
import SupplierCategories from '@/views/Supplier/Categories.vue'
import SupplierSubcategories from '@/views/Supplier/Subcategories.vue'
import SupplierProductsVersions from '@/views/Supplier/ProductsVersions.vue'
import SupplierProductVersion from '@/views/Supplier/Pages/ProductVersion.vue'


// // // Client // // // 
import ClientHome from '@/views/Client/Home.vue'
import ClientCategories from '@/views/Client/Categories.vue'
import ClientCategory from '@/views/Client/Category.vue'
import ClientProductsVersions from '@/views/Client/Pages/ProductsVersions.vue'
import ClientProduct from '@/views/Client/Pages/Product.vue'
import ClientProfile from '@/views/Auth/Profile.vue'
// import ClientProductsVersions from '@/views/Client/ProductsVersions.vue'
import ClientSuppliers from '@/views/Client/Suppliers.vue'
import ClientSupplier from '@/views/Client/Pages/User.vue'
import ClientOrders from '@/views/Client/Orders.vue'
import ClientCoupons from '@/views/Client/Coupons.vue'
import ClientWishlist from '@/views/Client/Wishlist.vue'
import ClientCheckout from '@/views/Client/Checkout.vue'
import ClientBank from '@/views/Client/Bank.vue'



const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: ClientHome,
    name: 'client home',
    meta: { loggedInHidden: false }
  },
  {
    path: '/loading',
    component: Loading
  },
  {
    path: '/privacyPolicy',
    component: privacyPolicy
  },


  // // // General // // //

  {
    path: '/contactus',
    component: ContactUs
  },
  
  // // // Auth // // //
  
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: { loggedInHidden: false }
  },
  {
    path: '/register',
    component: Register,
    meta: { loggedInHidden: false }
  },

  // // // Admin // // //

  {
    path: '/admin',
    redirect: '/admin/dashboard',
    meta: { loggedInHidden: true }
  },
  {
    path: '/admin/dashboard',
    component: AdminDashboard,
    name: 'admin dashboard',
    meta: { loggedInHidden: true }
  },
  {
    path: '/admin/orders',
    component: AdminOrders,
    name: 'admin orders',
    meta: { loggedInHidden: true }
  },
  {
    path: '/admin/products',
    component: AdminProducts,
    name: 'admin products',
    meta: { loggedInHidden: true }
  },
  {
    path: '/admin/categories',
    component: AdminCategories,
    name: 'admin categories',
    meta: { loggedInHidden: true }
  },
  {
    path: '/admin/category/:cid',
    component: AdminCategory,
    name: 'admin category',
    meta: { loggedInHidden: true }
  },
  {
    path: '/admin/category/:cid/subcategory/:scid',
    component: AdminProductsVersions,
    name: 'admin products versions',
    meta: { loggedInHidden: true }
  },
  {
    path: '/admin/product/:id',
    component: AdminProduct,
    meta: { loggedInHidden: true }
  },
  {
    path: '/admin/clients',
    component: AdminUsers,
    name: 'admin clients',
    meta: { loggedInHidden: true, type: 'Clients' }
  },
  {
    path: '/admin/clients/:id',
    component: AdminUser,
    meta: { loggedInHidden: true }
  },
  {
    path: '/admin/suppliers',
    component: AdminUsers,
    name: 'admin suppliers',
    meta: { loggedInHidden: true, type: 'Suppliers' }
  },
  {
    path: '/admin/suppliers/:id',
    component: AdminUser,
    meta: { loggedInHidden: true }
  },
  {
    path: '/admin/users',
    component: AdminUsers,
    name: 'admin users',
    meta: { loggedInHidden: true, type: 'Users' }
  },
  {
    path: '/admin/users/:id',
    component: AdminUser,
    meta: { loggedInHidden: true }
  },
  {
    path: '/admin/joinRequests',
    component: JoinRequests,
    name: 'admin join requests',
    meta: { loggedInHidden: true}
  },
  
  // // // Supplier // // //
  
  {
    path: '/supplier',
    redirect: '/supplier/dashboard',
    meta: { loggedInHidden: true }
  },
  {
    path: '/supplier/clients',
    component: SupplierClients,
    name: 'supplier clients',
    meta: { loggedInHidden: true }
  },
  {
    path: '/supplier/clients/:id',
    component: SupplierClient,
    meta: { loggedInHidden: true }
  },
  {
    path: '/supplier/dashboard',
    component: SupplierDashboard,
    name: 'supplier dashboard',
    meta: { loggedInHidden: true }
  },
  {
    path: '/supplier/orders',
    component: SupplierOrders,
    name: 'supplier orders',
    meta: { loggedInHidden: true }
  },
  {
    path: '/supplier/categories',
    component: SupplierCategories,
    name: 'supplier categories',
    meta: { loggedInHidden: true }
  },
  {
    path: '/supplier/category/:cid',
    component: SupplierSubcategories,
    name: 'supplier subcategories',
    meta: { loggedInHidden: true }
  },
  {
    path: '/supplier/category/:cid/subcategory/:scid',
    component: SupplierProductsVersions,
    name: 'supplier products versions',
    meta: { loggedInHidden: true }
  },
  // {
  //   path: '/supplier/productsVersions',
  //   component: SupplierProductsVersions,
  //   name: 'supplier products versions',
  //   meta: { loggedInHidden: true }
  // },
  {
    path: '/supplier/productsVersions/:id',
    component: SupplierProductVersion,
    meta: { loggedInHidden: true }
  },
  
  
  // // // Client // // //
  
  {
    path: '/client',
    redirect: '/',
    meta: { loggedInHidden: true }
  },
  // {
  //   path: '/client/home',
  //   component: ClientHome,
  //   name: 'client home',
  //   meta: { loggedInHidden: true }
  // },
  {
    path: '/client/categories',
    component: ClientCategories,
    name: 'client categories',
    meta: { loggedInHidden: false }
  },
  {
    path: '/client/category/:cid',
    component: ClientCategory,
    name: 'client subcategories',
    meta: { loggedInHidden: false }
  },
  // {
  //   path: '/client/category/:cid/subcategory/:scid',
  //   component: ClientProductsVersions,
  //   name: 'client products versions',
  //   meta: { loggedInHidden: false }
  // },
  {
    path: '/client/profile',
    component: ClientProfile,
    name: 'client Profile',
    meta: { loggedInHidden: true }
  },
  // {
  //   path: '/client/productsVersions',
  //   component: ClientProductsVersions,
  //   name: 'client products versions',
  //   meta: { loggedInHidden: false }
  // },
  {
    path: '/client/product/:id',
    component: ClientProduct,
    meta: { loggedInHidden: false }
  },
  {
    path: '/client/orders',
    component: ClientOrders,
    name: 'client orders',
    meta: { loggedInHidden: true }
  },
  {
    path: '/client/suppliers',
    component: ClientSuppliers,
    name: 'client suppliers',
    meta: { loggedInHidden: false }
  },
  {
    path: '/client/suppliers/:id',
    component: ClientSupplier,
    meta: { loggedInHidden: false }
  },
  {
    path: '/client/coupons',
    component: ClientCoupons,
    meta: { loggedInHidden: true }
  },
  {
    path: '/client/orders',
    component: ClientOrders,
    meta: { loggedInHidden: true }
  },
  {
    path: '/client/wishlist',
    component: ClientWishlist,
    meta: { loggedInHidden: true }
  },
  {
    path: '/client/checkout',
    component: ClientCheckout,
    meta: { loggedInHidden: false }
  },
  {
    path: '/client/bank',
    component: ClientBank,
    meta: { loggedInHidden: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  let isAuthenticated = false;
  let user = {role:{name_en:'guest'}}

  if(localStorage.getItem("user")){
    isAuthenticated = true;
    user = JSON.parse(localStorage.getItem("user") as string)
  }

  if (to.name == 'login' && isAuthenticated && user.role.name_en == 'admin') {
    next({ name: 'admin dashboard' })
  }
  else if (to.name == 'login' && isAuthenticated && user.role.name_en == 'supplier') {
    next({ name: 'supplier dashboard' })
  }
  else if (to.name == 'login' && isAuthenticated && user.role.name_en == 'client') {
    next({ name: 'client categories' })
  }
  else if (to.meta['loggedInHidden'] == true && !isAuthenticated && user.role.name_en == 'guest') {
    next({ name: 'login' })
  }
  else next()
})

export default router
