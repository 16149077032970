<template>
  <ion-page  v-if="user">
    <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
      <ion-toolbar style="--background: #ffffff00">
        <ion-buttons slot="start">
          <ion-back-button text="" v-if="!store.state.editUserFormOpened" color="primary" :defaultHref="'/client/' + route.path.split('/')[2]"></ion-back-button>
        </ion-buttons>
          <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px; margin-top: 20px" src="@/../public/assets/logos/101_logo_ideas_2.png"/></ion-title> -->
          <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #1f2e60">Storz</div></ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">

        <ion-grid style="margin-bottom: 20px" :dir="s.lang == 'ar' ? 'rtl' : 'ltr'">
          <ion-row style="display: flex; justify-content: center">
            <div style="padding: 5px; ">
              <img style="border-radius: 20px" :src="user.logo">
            </div>
          </ion-row>
          <ion-row>
            <div style="width: 100%; text-align: center">
              <div>{{switchLang(user.name_en, user.name_ar)}}</div>
              <div>{{switchLang(user.description_en, user.description_ar)}}</div>
            </div>
          </ion-row>
        </ion-grid>

        <ion-grid style="margin-bottom: 20px" :dir="s.lang == 'ar' ? 'rtl' : 'ltr'">
          <ion-row v-if="store.state.productsVersions.filter(p => p.user_id == route.params.id).length > 0">
            <ion-col size="6" v-for="pv in store.state.productsVersions.filter(p => p.user_id == route.params.id)" :key="pv.id">
              <div style="margin: 0px; padding: 5px; box-shadow: 0px 0px 5px #00000080; border-radius: 10px; height: 100%">
                <div @click="goToProductVersion(pv)" style="padding: 5px">
                  <img style="border-radius: 20px; height: 150px; width: 100%; object-fit: cover; overflow: hidden" :src="pv.product.image">
                </div>
                <div style="text-align: center">
                  <div style="color: #000098">{{switchLang(pv.product.name_en, pv.product.name_ar)}}</div>
                  <div style="font-size: 14px; color: #575757">{{switchLang(pv.product.description_en, pv.product.description_ar)}}</div>
                  <div style="font-size: 14px; color: #575757">{{pv.price}}</div>
                  <!-- <ion-row style="display: flex; justify-content: center">
                    <div style="color: #1f2e60" @click="addToWishlist(pv)" v-if="store.state.user.wishlist.find(p => p.id == pv.id)"><ion-icon size="large" :icon="heart"></ion-icon></div>
                    <div style="color: #1f2e60" @click="addToWishlist(pv)" v-if="!store.state.user.wishlist.find(p => p.id == pv.id)"><ion-icon size="large" :icon="heartOutline"></ion-icon></div>
                  </ion-row> -->
                  <div></div>
                </div>
              </div>
            </ion-col>
          </ion-row>
          <ion-row v-else style="display: flex; justify-content: center; margin-top: 150px; text-align: center; font-weight: 700; font-size: 20px; padding: 30px; color: #1f2e60">
            {{switchLang('unfortunately there are no products for this supplier', 'للأسف لا توجد منتجات الان لهذا المورد')}}
          </ion-row>
        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, computed, ref, onMounted } from 'vue'
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol } from '@ionic/vue';
import { heart, heartOutline, cart } from 'ionicons/icons';
import { useRoute } from 'vue-router'
import store from '@/store/index';
import router from '@/router/index';
import axios from 'axios'

    onMounted(() => {
      store.dispatch('getClientSuppliers')
      store.dispatch('getClientProductsVersions')
    })

    const switchLang = (en, ar) => {
      if(s.lang == 'en')
      { return en } 
      else if(s.lang == 'ar')
      { return ar }
    }

    const route = useRoute()

    const user = computed(() => {
      return store.state.suppliers.find(p => p.id == parseInt(route.params.id, 10))
    })

    const goToProductVersion = (pv) => {
      store.state.activeProductVersion = pv
      router.push('/client/productsVersions/' + pv.id)
    }

</script>

<style scoped>

</style>