<template>
    <ion-page  v-if="user">
      <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
        <ion-toolbar style="--background: #ffffff00">
          <ion-buttons slot="start">
            <ion-back-button text="" v-if="!store.state.editUserFormOpened" color="primary" :defaultHref="'/client/' + route.path.split('/')[2]"></ion-back-button>
          </ion-buttons>
            <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px; margin-top: 20px" src="@/../public/assets/logos/101_logo_ideas_2.png"/></ion-title> -->
            <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #1f2e60">Storz</div></ion-title>
          <ion-buttons slot="end" style="position: relative">
            <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 0px; right: 30px; padding: 0px 5px; z-index: 9; background: lightgreen; height: 20px; border-radius: 50px; text-align: center; font-size: 16px;">{{ store.getters.getCartItemsCount }}</div>
            <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 18px; right: 30px; padding: 0px 7px 0px 7px; z-index: 9; background: lightblue; height: 22px; border-radius: 50px; text-align: center; font-size: 16px"><span style="color:white" :dir="en ? 'ltr' : 'rtl'">{{en ? 'KD' : 'د.ك.'}}</span>{{ store.getters.getCartTotal }}</div>
            <ion-icon @click="router.push('/client/checkout')" size="large" :icon="cart" color="primary"></ion-icon>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      
      <ion-content :fullscreen="true">
  
          <ion-grid style="margin-bottom: 20px" :dir="s.lang == 'ar' ? 'rtl' : 'ltr'">
            <ion-row style="display: flex; justify-content: center">
              <div style="padding: 5px; ">
                <img style="border-radius: 20px" :src="user.logo">
              </div>
            </ion-row>
            <ion-row>
              <div style="width: 100%; text-align: center">
                <div  style="color: #D16BA5; font-size: 24px">{{switchLang(user.name_en, user.name_ar)}}</div>
                <div>{{switchLang(user.description_en, user.description_ar)}}</div>
              </div>
            </ion-row>
          </ion-grid>

          <ion-grid style="margin-bottom: 20px" :dir="s.lang == 'ar' ? 'rtl' : 'ltr'">
            <ion-row v-if="store.state.productsVersions && store.state.productsVersions.data && store.state.productsVersions.data.length > 0">
              <ProductCard v-for="p in store.state.productsVersions.data" :key="p.id" :product="p.product" :path="'/client/productsVersions/' + p.product.id"/>
            </ion-row>
            <ion-row v-else style="display: flex; justify-content: center; margin-top: 150px; text-align: center; font-weight: 700; font-size: 20px; padding: 30px; color: #1f2e60">
              {{switchLang('unfortunately there are no products for this supplier', 'للأسف لا توجد منتجات الان لهذا المورد')}}
            </ion-row>
          </ion-grid>

          <!-- Paginator -->
          <ion-row style="display:flex; justify-content: space-around; margin: 30px; text-align: center; color: #8759a6" v-if="store.state.productsVersions.current_page && store.state.productsVersions.last_page">
            <ion-col size="4" style="cursor: pointer">
              <ion-icon v-if="store.state.productsVersions.current_page != 1" @click="store.dispatch('getClientSupplierProductsVersions', {page: store.state.productsVersions.current_page - 1, supplierId: route.params.id})" :icon="arrowBack" size="large"></ion-icon>
            </ion-col>
            <!-- <div class="bg-white" style="box-shadow: 0px 0px 5px 0.1px; border-radius: 100px; padding: 5px 15px; cursor: pointer" :style="[store.state.productsVersions.current_page == p ? {border: '2px solid darkblue'} : {}]" v-for="p in store.state.productsVersions.last_page > 5 ? 5 : store.state.productsVersions.last_page" :key="p" @click="store.dispatch('getClientProducts', store.state.productsVersions.current_page > 3 ? p + 1 : p)">{{store.state.productsVersions.current_page > 3 ? p + 1 : p}}</div> -->
            <ion-col size="4" style="font-weight: 700; font-size:28px;">{{ store.state.productsVersions.current_page }} / {{ store.state.productsVersions.last_page }}</ion-col>
            <ion-col size="4" style="cursor: pointer">
              <ion-icon v-if="store.state.productsVersions.current_page != store.state.productsVersions.last_page" @click="store.dispatch('getClientSupplierProductsVersions', {page: store.state.productsVersions.current_page + 1, supplierId: route.params.id})" :icon="arrowForward" size="large"></ion-icon>
            </ion-col>
          </ion-row>
  
      </ion-content>
    </ion-page>
  </template>
  
<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, computed, ref, onMounted } from 'vue'
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { heart, heartOutline, cart, arrowForward, arrowBack } from 'ionicons/icons';
import { useRoute } from 'vue-router'
import store from '@/store/index';
import router from '@/router/index';
import ProductCard from '@/views/Components/ProductCard.vue'

onMounted(() => {
  store.dispatch('getClientSuppliers')
  store.dispatch('getClientSupplierProductsVersions', {supplierId: route.params.id})
})

const switchLang = (en, ar) => {
  if(s.lang == 'en')
  { return en } 
  else if(s.lang == 'ar')
  { return ar }
}

const route = useRoute()

const user = computed(() => {
  return store.state.suppliers.find(p => p.id == parseInt(route.params.id, 10))
})

const goToProductVersion = (pv) => {
  store.state.activeProductVersion = pv
  router.push('/client/productsVersions/' + pv.product.id)
}

</script>

<style scoped>

</style>