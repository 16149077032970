<template>
  <ion-page  v-if="store.state.activeProduct">

    <main-header/>
    
    <ion-content :fullscreen="true">

        <ion-grid style="margin-bottom: 20px" :dir="s.lang == 'ar' ? 'rtl' : 'ltr'">
          <ion-row>
            <ion-col>
              <div v-if="!store.state.editProductFormOpened" style="margin: 5px; padding: 5px; box-shadow: 0px 0px 5px #00000080; border-radius: 20px">
                <div style="cursor: pointer; display: flex; justify-content: space-between;; padding: 10px">
                  <!-- <ion-icon @click="deleteProduct()" size="large" :icon="trash" color="danger"></ion-icon> -->
                  <ion-icon @click="editProduct()" size="large" :icon="create" color="success"></ion-icon>
                </div>
                <div style="padding: 5px">
                  <img style="border-radius: 20px" :src="store.state.activeProduct.image">
                </div>
                <div style="text-align: center">
                  <div style="font-weight: 700">{{switchLang(store.state.activeProduct.name_en, store.state.activeProduct.name_ar)}}</div>
                  <div>{{switchLang(store.state.activeProduct.description_en, store.state.activeProduct.description_ar)}}</div>
                  <div style="font-weight: 700">{{store.state.activeProduct.price}} {{switchLang('KD', 'دك')}}</div>

                </div>
              </div>

              <ProductForm v-if="store.state.editProductFormOpened" :entryType="{process: 'Edit'}"/>

            </ion-col>
          </ion-row>
        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, computed, ref, onMounted } from 'vue'
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { trash, create } from 'ionicons/icons';
import { useRoute } from 'vue-router'
import store from '@/store/index';
import router from '@/router/index';
import ProductForm from '@/views/Admin/Pages/ProductForm.vue'
import axios from 'axios'
import MainHeader from '@/views/Admin/Components/MainHeader.vue'

const switchLang = (en, ar) => {
  if(s.lang == 'en')
  { return en } 
  else if(s.lang == 'ar')
  { return ar }
}

onMounted(() => {
  store.dispatch('getSupplierProductAndVersions', route.params.id)
})

const route = useRoute()
// const product = computed(() => store.state.products.data.find(p => p.id == parseInt(route.params.id, 10)))
const quantity = ref(1)

const editProduct = () => {
  store.state.productToEdit = {...store.state.activeProduct}
  store.state.productToEditImage = store.state.productToEdit.image
  store.state.editProductFormOpened = true
}

const deleteProduct = () => {
  axios({
      method: 'delete',
      url:`${store.state.api}products/${route.params.id}`,
      headers: {
      'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
      'Content-Type': 'application/x-www-form-urlencoded'
      // 'Content-Type': 'multipart/form-data'
      }
  }).then( () => {
      store.state.toastMessage = 'Product Deleted!'
      store.state.toastColor = 'success'
      store.dispatch('openToast')
      store.dispatch('getAdminProducts',{page: 1, subcategory_id: route.params.id})

      router.push({name:'admin products'})

      // location.reload()
      
  }).catch((errors) => {
      store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
      store.state.toastColor = 'danger'
      store.dispatch('openToast')
  })
}

</script>

<style scoped>

</style>