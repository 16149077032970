<template>
  <ion-page>
      <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
        <ion-toolbar style="--background: #ffffff00">
          <ion-buttons slot="start">
            <ion-menu-button color="primary"></ion-menu-button>
          </ion-buttons>
            <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
            <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #1f2e60">Storz</div></ion-title>
          <ion-buttons slot="end" style="position: relative">
            <!-- <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 0px; right: 30px; padding: 0px 5px; z-index: 9; background: lightgreen; height: 20px; border-radius: 50px; text-align: center; font-size: 16px;">{{ store.getters.getCartItemsCount }}</div>
          <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 20px; right: 30px; padding: 0px 5px; z-index: 9; background: lightblue; height: 20px; border-radius: 50px; text-align: center; font-size: 16px"><span style="color:white">KD</span>{{ store.getters.getCartTotal }}</div> -->
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      
      <ion-content :fullscreen="true">
        <ion-grid>
          <ion-row>
            <ion-col>
              <div style="padding: 5px 10px; border-radius: 50px; border: 1px solid black">
                <ion-input v-model="search" placeholder="Search"/>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>

        <!-- <ion-grid v-if="store.state.categories.length > 0">
          <ion-row>
            <ion-col v-for="cat in store.state.categories" :key="cat.id">
              <div @click="activateCategory(cat.id)" class="catpill" :style="store.state.activeCategory == cat.id ? {background : '#f8c2f6'} : {}">{{ en ? cat.name_en : cat.name_ar }}</div>
            </ion-col>
          </ion-row>
          <ion-row v-if="store.state.activeCategory != 0 ">
            <ion-col v-for="scat in store.state.categories.find(c=>c.id == store.state.activeCategory).subcategories" :key="scat.id">
              <div @click="activateSubcategory(scat.id)" class="catpill" :style="store.state.activeSubcategory == scat.id ? {background : '#f8c2f6'} : {}">{{ en ? scat.name_en : scat.name_ar }}</div>
            </ion-col>
          </ion-row>
        </ion-grid> -->
        
        <div v-if="!store.state.editProductFormOpened">
          <ion-grid style="margin-bottom: 20px" :dir="s.lang == 'ar' ? 'rtl' : 'ltr'">
          <ion-row v-if="products && products.length > 0">
            <ion-col size="6" v-for="product in products" :key="product.id">
              <div style="margin: 0px; padding: 5px; box-shadow: 0px 0px 5px #00000080; border-radius: 10px; height: 100%">
                <div @click="goToProduct(product)" style="padding: 5px">
                  <img style="border-radius: 20px; height: 150px; width: 100%; object-fit: cover; overflow: hidden" :src="product.image">
                </div>
                <div style="text-align: center">
                  <div style="color: #000098">{{switchLang(product.name_en, product.name_ar)}}</div>
                  <div style="font-size: 14px; color: #575757">{{switchLang(product.description_en, product.description_ar)}}</div>
                </div>
              </div>
            </ion-col>
          </ion-row>
          <ion-row v-else style="display: flex; justify-content: center; margin-top: 150px; text-align: center; font-weight: 700; font-size: 20px; padding: 30px; color: #1f2e60">
            {{switchLang('unfortunately there are no products matching your criteria', 'للأسف لا توجد منتجات الان توافق اختياراتك')}}
          </ion-row>
        </ion-grid>

        <!-- Paginator -->
        <ion-row style="display:flex; justify-content: space-around; margin: 30px 30px 10px 30px; color: #8759a6" v-if="store.state.products.current_page && store.state.products.last_page">
          <ion-col size="4" style="cursor: pointer; text-align: right">
            <ion-icon v-if="store.state.products.current_page != 1" @click="store.dispatch('getAdminProducts', store.state.products.current_page - 1)" :icon="arrowBack" size="large"></ion-icon>
          </ion-col>
          <!-- <div class="bg-white" style="box-shadow: 0px 0px 5px 0.1px; border-radius: 100px; padding: 5px 15px; cursor: pointer" :style="[store.state.products.current_page == p ? {border: '2px solid darkblue'} : {}]" v-for="p in store.state.products.last_page > 5 ? 5 : store.state.products.last_page" :key="p" @click="store.dispatch('getAdminProducts', store.state.products.current_page > 3 ? p + 1 : p)">{{store.state.products.current_page > 3 ? p + 1 : p}}</div> -->
          <ion-col size="4" style="font-weight: 700; font-size:28px; text-align: center">{{ store.state.products.current_page }} / {{ store.state.products.last_page }}</ion-col>
          <ion-col size="4" style="cursor: pointer; text-align: left">
            <ion-icon v-if="store.state.products.current_page != store.state.products.last_page" @click="store.dispatch('getAdminProducts', store.state.products.current_page + 1)" :icon="arrowForward" size="large"></ion-icon>
          </ion-col>
        </ion-row>

      </div>

      <div v-if="store.state.editProductFormOpened">
        <ProductForm v-if="store.state.editProductFormOpened" :entryType="{process: 'New'}"/>
      </div>

      <div v-if="!store.state.editProductFormOpened" style="position: fixed; bottom: 0px; right: 20px">
        <div @click="store.state.editProductFormOpened = true" style="color: lightgreen; font-size: 72px;"><ion-icon :icon="addCircle"></ion-icon></div>
      </div>
  
    </ion-content>
  </ion-page>
</template>
    
<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, computed, onMounted, ref } from 'vue'
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonIcon, IonGrid, IonRow, IonCol, IonInput } from '@ionic/vue'
import { addCircle, arrowBack, arrowForward } from 'ionicons/icons';
import ProductForm from '@/views/Admin/Pages/ProductForm.vue'

import store from '@/store/index';
import router from '@/router/index';
import axios from 'axios'

const en = computed(() => s.lang == 'en')



const switchLang = (en, ar) => {
  if(s.lang == 'en')
  { return en } 
  else if(s.lang == 'ar')
  { return ar }
}

const search = ref('')

const products = computed(() => {
  if(search.value == ''){
    return store.state.products
  }else{
    return store.state.products.filter(p => {
      return p.name_en.toLowerCase().includes(search.value.toLowerCase()) || 
            p.name_ar.toLowerCase().includes(search.value.toLowerCase())
    })
  }
})

const goToProduct = (product) => {
  store.state.activeProduct = product
  router.push('/admin/product/' + product.id)
}

const addToWishlist = (product) => {
  if(store.state.user.wishlist.includes(product)){
    store.state.user.wishlist.splice(store.state.user.wishlist.indexOf(product),1)
    localStorage.setItem('wishlist', JSON.stringify(store.state.user.wishlist))
  } else {
    store.state.user.wishlist.push(product)
    localStorage.setItem('wishlist', JSON.stringify(store.state.user.wishlist))
  }
}

const activateCategory = (c) => {
  if(store.state.activeCategory != c){
    store.state.activeCategory = c
  } else {
    store.state.activeCategory = 0
  }
}

const activateSubcategory = (sc) => {
  if(store.state.activeSubcategory != sc){
    store.state.activeSubcategory = sc

    axios({
      method: 'get',
      url:`${store.state.api}client_products?subcategory_id=${sc}`,
      headers: {
      'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then(response => {
      store.state.products = response.data.data
      localStorage.setItem('products', JSON.stringify(response.data.data))            
    }).catch((errors) => {
      console.log(errors)
      // //store.state.loading.dismiss()
      store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
      store.state.toastColor = 'danger'
      store.dispatch('openToast')
    })

  } else {
    store.state.activeSubcategory = 0

    axios({
      method: 'get',
      url:`${store.state.api}client_products`,
      headers: {
      'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then(response => {
      store.state.products = response.data.data
      localStorage.setItem('products', JSON.stringify(response.data.data))            
    }).catch((errors) => {
      console.log(errors)
      // //store.state.loading.dismiss()
      store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
      store.state.toastColor = 'danger'
      store.dispatch('openToast')
    })
  }
}
          
</script>

<style scoped>
.catpill{
  border: 2px solid #6d327c;
  border-radius: 100px;
  text-align: center;
  padding: 5px;

}
</style>