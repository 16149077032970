<template>
  <ion-page>
    <main-header />
    <ion-content :fullscreen="true" >
      <transition name="content">
        <div>

          <ion-grid>
            <ion-row>
              <ion-col size="6" sizeMd="3" v-for="c in store.state.categories" :key="c.id" >
                <div @click="()=>{s.getClientCategory(c.id); router.push(`/client/category/${c.id}`)}" style="cursor: pointer;">
                  <div>                  
                    <img :style="category == c.nameEn ? {border: '3px solid #000098'} : {border: '3px solid #00000000'}" style="width: 100%; border-radius: 20px; " class="btnFkt" :src="c.image"/>
                  </div>
                  <!-- <div>
                    <div style="text-align: center; color: #000098; font-size: 14px; white-space: nowrap;">{{switchLang(c.name_en, c.name_ar)}}</div>
                  </div> -->
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-grid style="margin-bottom: 20px" :dir="s.lang == 'ar' ? 'rtl' : 'ltr'">
            <ion-row v-if="products && products.length > 0">
                <ion-col size="6" v-for="p in products" :key="p.id">
                  <div style="margin: 0px; padding: 5px; box-shadow: 0px 0px 5px #00000080; border-radius: 10px; height: 100%">
                    <div @click="goToProductVersion(p)" style="padding: 5px">
                      <img style="border-radius: 20px; height: 150px; width: 100%; object-fit: cover; overflow: hidden" :src="p.image">
                    </div>
                    <div style="text-align: center">
                      <div style="color: #000098">{{switchLang(p.name_en, p.name_ar)}}</div>
                      <div style="font-size: 14px; color: #575757">{{switchLang(p.description_en, p.description_ar)}}</div>
                      <div style="font-size: 14px; color: #575757">{{p.price}}</div>
                    <div></div>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>

          <!-- Paginator -->
          <ion-row style="display:flex; justify-content: space-around; margin: 30px; text-align: center; color: #8759a6" v-if="store.state.products.current_page && store.state.products.last_page">
            <ion-col size="4" style="cursor: pointer">
              <ion-icon v-if="store.state.products.current_page != 1" @click="store.dispatch('getClientProducts', store.state.products.current_page - 1)" :icon="arrowBack" size="large"></ion-icon>
            </ion-col>
            <!-- <div class="bg-white" style="box-shadow: 0px 0px 5px 0.1px; border-radius: 100px; padding: 5px 15px; cursor: pointer" :style="[store.state.products.current_page == p ? {border: '2px solid darkblue'} : {}]" v-for="p in store.state.products.last_page > 5 ? 5 : store.state.products.last_page" :key="p" @click="store.dispatch('getClientProducts', store.state.products.current_page > 3 ? p + 1 : p)">{{store.state.products.current_page > 3 ? p + 1 : p}}</div> -->
            <ion-col size="4" style="font-weight: 700; font-size:28px;">{{ store.state.products.current_page }} / {{ store.state.products.last_page }}</ion-col>
            <ion-col size="4" style="cursor: pointer">
              <ion-icon v-if="store.state.products.current_page != store.state.products.last_page" @click="store.dispatch('getClientProducts', store.state.products.current_page + 1)" :icon="arrowForward" size="large"></ion-icon>
            </ion-col>
          </ion-row>

        </div>
      </transition>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, ref, computed, onMounted, reactive } from 'vue'
import { IonInput, IonLabel, IonSegment, IonSegmentButton, IonSlides, IonSlide, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { cart, heart, heartOutline, arrowBack, arrowForward } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';
// import Coin from '@/components/elements/toys/CoinIcon.vue'
import axios from 'axios'
import MainHeader from '@/views/Admin/Components/MainHeader.vue'

const en = computed(() => s.lang == 'en')

onMounted(()=>{
  // store.dispatch('getClientProducts')
})


setTimeout(() => {
  s.state.headerClass = ''
}, 4000)

const slider = ref()

const slideNext = async () => { await slider.value.$el.slideNext() }
setInterval(() => {
  if(slider.value){
    slideNext()
  }
}, 3000)

const gender = ref('all')
const category = ref('all')
const ageRange = ref('all')

// const products = computed(() => {
//   return store.state.products.filter(p => p.categories ? p.categories.includes(mainCategory.value) || p.categories.includes(subcategory.value) : true)
// })

const search = ref('')

const products = computed(() => {
  if(search.value == ''){
    return store.state.products.data
  }else{
    return store.state.products.data.filter(p => {
      return p.product.name_en.toLowerCase().includes(search.value.toLowerCase()) || 
            p.product.name_ar.toLowerCase().includes(search.value.toLowerCase())
    })
  }
})

const segmentChanged = (ev) => {
  gender.value = ev.detail.value
}

const switchLang = (en, ar) => {
  if(s.lang == 'en')
  { return en } 
  else if(s.lang == 'ar')
  { return ar }
}

const goToProductVersion = (pv) => {
  router.push('/client/productsVersions/' + pv.id)
}

const slideOpts = {
  initialSlide: 1,
  speed: 1000,
  loop: true
};

const addToWishlist = (product) => {
  if(store.state.user.wishlist.includes(product)){
    store.state.user.wishlist.splice(store.state.user.wishlist.indexOf(product),1)
    localStorage.setItem('wishlist', JSON.stringify(store.state.user.wishlist))
  } else {
    store.state.user.wishlist.push(product)
    localStorage.setItem('wishlist', JSON.stringify(store.state.user.wishlist))
  }
}


const activateCategory = (c) => {
  if(store.state.activeCategory != c){
    store.state.activeCategory = c
  } else {
    store.state.activeCategory = 0
  }
}

const activateSubcategory = (sc) => {
  if(store.state.activeSubcategory != sc){
    store.state.activeSubcategory = sc

    axios({
      method: 'get',
      url:`${store.state.api}client_products?subcategory_id=${sc}`,
      headers: {
      'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then(response => {
      store.state.products = response.data.data
      localStorage.setItem('products', JSON.stringify(response.data.data))            
    }).catch((errors) => {
      console.log(errors)
      // //store.state.loading.dismiss()
      store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
      store.state.toastColor = 'danger'
      store.dispatch('openToast')
    })

  } else {
    store.state.activeSubcategory = 0

    axios({
      method: 'get',
      url:`${store.state.api}client_products`,
      headers: {
      'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
      }
    }).then(response => {
      store.state.products = response.data.data
      localStorage.setItem('products', JSON.stringify(response.data.data))            
    }).catch((errors) => {
      console.log(errors)
      // //store.state.loading.dismiss()
      store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
      store.state.toastColor = 'danger'
      store.dispatch('openToast')
    })
  }


}

</script>

<style scoped>

.btnFkt:hover{
  transition-duration: 0.1s;
  box-shadow: 0px 0px 5px #00000080;
  scale: 1.05;
}
.btnFkt:active{
  transition-duration: 0.1s;
  box-shadow: 0px 0px 5px #00000080;
  scale: 0.9;
}

.fade-in {
  animation: fade-in 0.3s ease-out forwards;
}
.header-fade-in {
  animation: header-fade-in 4s ease-in forwards;
}

@keyframes header-fade-in{
  0%{
    opacity: 0
  }
  75%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
}
@keyframes fade-in{
  0%{
    opacity: 0
  }
  50%{
    opacity: 0.5
  }
  100%{
    opacity: 1
  }
}
.fade-out {
  animation: fade-out 1s ease-out forwards;
}

@keyframes fade-out{
  0%{
    opacity: 1
  }
  50%{
    opacity: 0.5
  }
  100%{
    opacity: 0
  }
}

  .overlay-enter-active {
    transition: all 1s ease-out;
  }

  .overlay-leave-active {
    transition: all 1s ease-out;
  }

  .overlay-enter-from,
  .overlay-leave-to {
    /* transform: translateY(-100%); */
    opacity: 0;
  }

  .content-enter-active {
    transition: all 2s ease-out;
  }

  .content-leave-active {
    transition: all 2s ease-out;
  }

  .content-enter-from,
  .content-leave-to {
    /* transform: translateY(-100%); */
    opacity: 0;
  }

.header-bg{
  background-image: linear-gradient(to bottom top, #6d327c, #485DA6, #00a1ba, #00BF98, #36C486);
}

.catpill{
  border: 2px solid #6d327c;
  border-radius: 100px;
  text-align: center;
  padding: 5px;

}
</style>