<template>

<ion-header :translucent="false" style=" background: white">
  <ion-toolbar :style="[{'--background': s.state.activeTheme.accent3}]">
    <ion-buttons slot="end" class="ion-hide-sm-up">
      <ion-menu-button :style="[{color: s.state.activeTheme.accent1}]"></ion-menu-button>
    </ion-buttons>
      <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
    <!-- <ion-buttons slot="end" style="position: relative"> -->
        <!-- <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 0px; right: 30px; padding: 0px 5px; z-index: 9; background: lightgreen; height: 20px; border-radius: 50px; text-align: center; font-size: 16px;">{{ store.getters.getCartItemsCount }}</div>
          <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 20px; right: 30px; padding: 0px 5px; z-index: 9; background: lightblue; height: 20px; border-radius: 50px; text-align: center; font-size: 16px"><span style="color:white">KD</span>{{ store.getters.getCartTotal }}</div> -->
    <!-- </ion-buttons> -->
    <div style="display: flex; justify-content: space-between;">
      <div style="display: flex; ">
        <div style="cursor: pointer; cursor: pointer; padding:0px 10px 0px 0px" @click="router.push(!store.state.loggedIn || store.state.user.role.name == 'client' ? '/' : '/admin/dashboard')">
          <img style="position: absolute; top: 13px; left: 0px; width: 20px; margin-left: 20px" src="@/../public/assets/images/tj-logo.png"/> 
        </div>
        <div style="padding: 0px 0px 0px 30px">
          <div style="border: 1px solid black; height: 30px; border-radius:50px; padding: 2px;  margin: 15px; display: flex">
            <ion-input style="padding: 5px; " v-model="searchTerm" placeholder=""/>
            <div>
              <ion-icon style="background: #336666; color: white; padding: 4px; font-size: 15px; border-radius: 50px; width: 30px" :icon="search"/>            
            </div>
          </div>
        </div>
        <div v-if="store.state.user.role.name == 'client' || !store.state.loggedIn" @click="router.push('/client/checkout')" style="cursor: pointer; padding: 15px 10px 0px 10px; margin-left: 20px; position: relative">
          <ion-icon :icon="cart"/> 
          <div v-if="Object.keys(s.state.user.cart).length > 0" style="position: absolute; bottom: 7px; right: 10px; color: white">{{ Object.keys(s.state.user.cart).length }}</div>
        </div>
        <div @click="router.push('/client/profile')" v-if="s.state.loggedIn && store.state.user.role.name == 'client'" style="cursor: pointer; padding: 15px 10px 0px 10px">
          <ion-icon :icon="person"/> 
          <!-- <profile /> -->
        </div>
      </div>
      <div style="display: flex; justify-content: space-between" class="ion-hide-sm-down">
        <div style="display: flex; justify-content: space-around; padding: 10px">
          <div style="cursor: pointer; padding:0px 10px" v-for="(p, i) in s.appPages" :key="i" @click="router.push(p.url)">{{ en ? p.title : p.titleAr}}</div>
          <div v-if="store.state.loggedIn" style="cursor: pointer; padding:5px 10px"><ion-icon @click="logout()" :icon="logOutOutline"/></div>
          <div>
            <div style="cursor: pointer; padding: 2px 5px" @click="s.changeLang()">
              <img v-if="s.lang == 'ar'" style="width: 20px; margin-left: 20px" src="@/../public/assets/images/kuwait.png"/> 
              <img v-else style="width: 20px; margin-left: 20px" src="@/../public/assets/images/uk.png"/> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-toolbar>
  <div style="text-align: center; margin-top: 10px; background: white" v-if="props.title">
    <div style="width:100%; text-align: center; font-size: 32px; font-weight: 700" :style="[{color: s.state.activeTheme.accent2}]">{{ en ? props.title.en : props.title.ar }}</div>
  </div>
</ion-header>

<transition name="overlay">
  <loading v-if="s.state.intro" style="z-index: 99; height: 100%; position: fixed; top: 0px"/>
</transition>

</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { computed, defineProps, ref } from 'vue'
import { IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonInput, IonIcon } from '@ionic/vue';
import store from '@/store/index';
import router from '@/router/index';
import { person, cart, search, logOutOutline } from 'ionicons/icons';
import Loading from '@/views/Loading.vue'
import Profile from '@/components/elements/animations/Profile.vue'
const en = computed(() => s.lang == 'en')

const props = defineProps(['title'])

const searchTerm = ref('')

setTimeout(() => {
  s.state.intro = false
}, 500)

// const getCartItemsCount = computed(()=>{
//   return Object.values(state.user.cart).reduce((acc,supplier:any)=>acc + supplier.items.reduce((ac:number,item:any) => ac + parseInt(item.quantity),0),0)
// }),

const logout = () => {
  localStorage.clear()
  store.state.loggedIn = false
  store.state.user.role.name = 'guest'
  s.state.loggedIn = false
  s.state.user.role.name = 'guest'
  router.push('/login')
}

</script>

<style scoped>

.fade-in {
  animation: fade-in 5s ease-out forwards;
}

@keyframes fade-in{
  0%{
    opacity: 0
  }
  50%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
}
</style>