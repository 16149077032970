<template>

<div class="fade" style="width: 100%; height: 100%; display: flex; align-items: center; position: relative; justify-content: center;">
  <div style="background: white; height: 100%; width: 100%; position: absolute;"></div>
  <!-- <Balloons style="position: absolute; top: 0px; left: 0px; width: 200px; height: 200px"/>
  <BeachBall style="position: absolute; bottom: 120px; left: 90px; width: 200px; height: 200px"/>
  <Bear style="position: absolute; bottom: 100px; left: 90px; width: 200px; height: 200px"/>
  <Cadeau style="position: absolute; bottom: 10px; right: -10px; width: 200px; height: 200px"/>
  <CarToy style="position: absolute; top: 0px; right: 0px; width: 200px; height: 200px"/>
  <Drone style="position: absolute; top: 160px; left: 90px; width: 200px; height: 200px"/>
  <Teddy style="position: absolute; bottom: 10px; left: -10px; width: 200px; height: 200px"/> -->

  <div style="width:100%; padding: 70px; display: flex; align-items: center; justify-content: center;" class="zoom-in-zoom-out">
      <img style="height: 200px;" src="@/../public/assets/images/tj-logo.png"/>
  </div>
</div>

</template>

<script>
import { defineComponent } from 'vue'
import router from '@/router/index';

// import Balloons from '@/components/elements/toys/Balloons.vue'
// import BeachBall from '@/components/elements/toys/BeachBall.vue'
// import Bear from '@/components/elements/toys/Bear.vue'
// import Cadeau from '@/components/elements/toys/Cadeau.vue'
// import CarToy from '@/components/elements/toys/CarToy.vue'
// import Drone from '@/components/elements/toys/Drone.vue'
// import Teddy from '@/components/elements/toys/Teddy.vue'


export default defineComponent({

    name: 'Loading',

    components: {
      // Balloons,
      // BeachBall,
      // Bear,
      // Cadeau,
      // CarToy,
      // Drone,
      // Teddy,
    },

    setup(){
        // setTimeout(() => {
        //     router.push('/home')
        // }, 3000)
        return {

        }
    }
})
</script>

<style>

.zoom-in-zoom-out {
  animation: zoom-in-zoom-out 0.5s ease-out infinite;

}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.6, 1.6);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* .fade {
  animation: fade 3s ease-out forwards;
} */

/* @keyframes fade {
  0% {
    opacity: 1
  }
  
  50% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
} */

</style>