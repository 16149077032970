<template>
    <ion-page>

      <main-header />
      
      <ion-content :fullscreen="true" style="position: relative">
  
        <ion-row style=""  class="popOver">
            <ion-col v-auto-animate >
              <ion-row style=" display: flex; justify-content: center; margin: 0px 0px 0px 0px; padding: 30px 30px 0px 30px">
                <!-- <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && registerForm.join_code == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="registerForm.join_code" :placeholder="switchLang('Join Code', 'كود الانضمام')"></ion-input>
                </ion-col> -->
                <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && registerForm.phone_number == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="store.state.user.phone_number" :placeholder="switchLang('Phone Number', 'رقم التليفون')"></ion-input>
                </ion-col>
                <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && store.state.user.email == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="store.state.user.email" :placeholder="switchLang('Email', 'البريد الالكتروني')"></ion-input>
                </ion-col>
                <!-- <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && store.state.user.password == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="store.state.user.password" :placeholder="switchLang('Password', 'كلمة السر')"></ion-input>
                </ion-col>
                <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && store.state.user.password_confirmation == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="store.state.user.password_confirmation" :placeholder="switchLang('Password Confirmation', 'تأكيد كلمة السر')"></ion-input>
                </ion-col> -->
                <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && store.state.user.name == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="store.state.user.name" :placeholder="switchLang('Name', 'الاسم')"></ion-input>
                </ion-col>
                <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && store.state.user.address == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="store.state.user.address" :placeholder="switchLang('Address', 'العنوان')"></ion-input>
                </ion-col>
                <!-- <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && store.state.user.field == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="registerForm.field" :placeholder="switchLang('Field', 'المجال')"></ion-input>
                </ion-col> -->
              </ion-row>

              <ion-row style="width: 100%">
                <ion-col style="display: flex; justify-content: center; width: 100%">
                    <div @click="register()" style="cursor: pointer; background: #b9fd99; padding: 10px; margin: 10px; border-radius: 20px; width: 200px; text-align: center; font-size: 20px; font-weight: 700">{{switchLang('Register', 'تسجيل')}}</div>
                </ion-col>
              </ion-row>

              <ion-row style="width: 100%">
                  <ion-col style="display: flex; justify-content: center; width: 100%">
                      <div @click="router.push('/login')" style="cursor: pointer; background: #1f2e60; padding: 10px; margin: 10px; border-radius: 20px; width: 200px; text-align: center; font-size: 20px; font-weight: 700; color: white">{{switchLang('Back to Login', 'الرجوع الى الدخول')}}</div>
                  </ion-col>
                </ion-row>

            </ion-col>
          </ion-row>
          
          <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
            <div @click="showConfirmDeletion()" style="width: 50%; text-align: center; padding: 5px; background: red; color: white; font-weight: 700; border-radius: 20px">{{ en ? 'Delete account' : 'مسح الحساب' }}</div>
          </div>
  
      </ion-content>
    </ion-page>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, ref, reactive, computed } from 'vue'
import {  IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonIcon, alertController, IonInput, IonCol, IonRow } from '@ionic/vue';
import { cart, heart, heartOutline, call } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';
import axios from 'axios';

import MainHeader from '@/views/Admin/Components/MainHeader.vue'

const en = computed(() => s.lang == 'en')


const registerForm = reactive({
    phone_number: '',
    email: '',
    password: '',
    password_confirmation: '',
    name: '',
    address: '',
})


const switchLang = (en, ar) => {
    if(s.lang == 'en')
    { return en } 
    else if(s.lang == 'ar')
    { return ar }
}

const registerFormValidationErrorShow = ref(false)

const logout = () => {
    localStorage.clear()
    store.state.loggedIn = false
    store.state.user.role.name = 'guest'
    router.push('/login')
}

const deleteUser = () => {
    axios({
        method: 'delete',
        url:`${store.state.api}users/${store.state.user.id}`,
        headers: {
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
        'Content-Type': 'application/x-www-form-urlencoded'
        // 'Content-Type': 'multipart/form-data'
        }
    }).then( () => {
        store.state.toastMessage = 'User Deleted!'
        store.state.toastColor = 'success'                
    }).catch((errors) => {
        store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
        store.state.toastColor = 'danger'
        store.dispatch('openToast')
    })
}

const showConfirmDeletion = async () => {
const alert = await alertController.create({
    header: en.value ? 'Are you sure you want to delete your account? This is irrreversible' : 'هل انت متأكد انك تريد مسح حسابك؟ هذه الخطوة لا يمكن الرجوع فيها',
    buttons: [
        {
        text: en.value ? 'Cancel' : 'رجوع',
        role: 'cancel',
        handler: () => {
//
        },
        },
        {
        text: en.value ? 'Delete Account' : 'مسح الحساب',
        role: 'confirm',
        handler: () => {
            console.log('deleted')
            deleteUser()
            logout()
        },
        },
    ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
};

</script>

<style scoped>

</style>