<template>
    <ion-page>

      <main-header v-if="Object.keys(s.state.category).length > 0" :title="{en:s.state.category.category.name_en,ar: s.state.category.category.name_ar}"/>

      <ion-content :fullscreen="true">
  
        <ion-grid>
            <ion-row style="margin-bottom: 50px">
                <CategoryButton style="cursor: pointer; " @click="router.push(`/admin/product/` + c.id)" v-for="c in s.state.category.featuredProducts" :key="c.id" :category="c"/>
            </ion-row>

        </ion-grid>
      </ion-content>
    </ion-page>
  </template>
  
<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import MainHeader from '@/views/Admin/Components/MainHeader.vue'
import { ref, computed, onMounted } from 'vue'
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon, IonInput } from '@ionic/vue';
import { cart, heart, heartOutline, arrowBack, arrowForward } from 'ionicons/icons';
import router from '@/router/index';
import CategoryButton from '@/views/Components/CategoryButton.vue'

import { useRoute } from 'vue-router';
const route = useRoute()

const en = computed(() => s.lang == 'en')

onMounted(() => {
  s.getClientCategory(route.params.cid)
})
  
</script>

<style scoped>

.header-fade-in {
  animation: header-fade-in 2s ease-in forwards;
}

@keyframes header-fade-in{
  0%{
    opacity: 0
  }
  75%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
}

</style>