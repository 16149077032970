<template>
  <ion-page>

    <main-header :title="{en:'Orders',ar:'الطلبات'}"/>
    
    <ion-content :fullscreen="true" style="position: relative">

        <ion-grid>
          <ion-row>
            <OrderCard size="12" v-for="order in store.state.orders" :key="order.id" :order="order"/>              
          </ion-row>
        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, ref, computed, onMounted } from 'vue'
import {  IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonIcon } from '@ionic/vue';
import { cart, heart, heartOutline, arrowForward, arrowBack } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';
import OrderCard from './Components/OrderCard.vue'

import MainHeader from '@/views/Admin/Components/MainHeader.vue'

const en = computed(() => s.lang == 'en')

onMounted(() => {
  store.dispatch('getAdminOrders')
})

</script>

<style scoped>

.fade-in {
  animation: fade-in 5s ease-out forwards;
}

@keyframes fade-in{
  0%{
    opacity: 0
  }
  50%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
}
</style>