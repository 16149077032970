<template>
  <ion-page>

    <main-header />
    
    <ion-content :fullscreen="true" style="position: relative">

        <ion-grid>
          <ion-row style="display: flex; justify-content: center; width: 100%; font-size: 32px; margin-top: 30px">
            {{switchLang('Leave us a message', 'اتركوا لنا رسالة')}}
          </ion-row>
            <ion-row>
          <ion-row :dir="s.lang == 'ar' ? 'rtl' : 'ltr'" style="width: 100%">
            <ion-col size="12" style="display: flex; justify-content: center; padding: 0 10%">
              <ion-textarea :placeholder="switchLang('Message', 'الرسالة')"></ion-textarea>
            </ion-col>
            <ion-col size="12" style="display: flex; justify-content: center; padding: 0 10%">
              <ion-input :placeholder="switchLang('Phone Number', 'رقم التليفون')"></ion-input>
            </ion-col>
          </ion-row>
            <ion-row style="width: 100%">
              <ion-col style="display: flex; justify-content: center; width: 100%">
                  <div @click="contactUs" style="background: lightgreen; padding: 10px; margin: 10px; border-radius: 20px; width: 200px; text-align: center; font-size: 20px">{{switchLang('Contact us', 'تواصلوا معنا')}}</div>
              </ion-col>
          </ion-row>
          </ion-row>
          <ion-row style="display: flex; justify-content: center; width: 100%; font-size: 32px; margin-top: 30px">
              {{switchLang('Contact Us Directly', 'تواصلوا معنا مباشرة')}}
          </ion-row>
          <ion-row style="display: flex; justify-content: center; width: 100%; font-size: 20px">
              {{switchLang('Working hours from 11am to 10pm', 'اوقات العمل من ١١ صباحا الى ١٠ مسائا')}}
          </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-col size="3" style="padding: 15px; display: flex; justify-content: center; ">
                  <a @click="track({type: 'ad', type_action: 'call', type_id: ad.id})" :href="'tel: 69988524'"><ion-icon style="color: black" size="large" :icon="call"></ion-icon></a>
              </ion-col>
              <ion-col size="3" style="display: flex; justify-content: center; ">
                  <div @click="track({type: 'ad', type_action: 'whatsapp', type_id: ad.id})" color="black" :href="'https://api.whatsapp.com/send?phone=96560407111'"><ion-img style="cursor: pointer; width:40px;" src="@/../assets/images/whatsapp-200.png"></ion-img></div>
              </ion-col>
            </ion-row>
        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, ref, computed } from 'vue'
import {  IonButton, IonImg, IonTextarea, IonInput, IonItem, IonLabel, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { cart, heart, heartOutline, call } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';

import MainHeader from '@/views/Admin/Components/MainHeader.vue'

const en = computed(() => s.lang == 'en')

const switchLang = (en, ar) => {
  if(s.lang == 'en')
  { return en } 
  else if(s.lang == 'ar')
  { return ar }
}

const contactUs = () => {
    router.push('/')
}

</script>

<style scoped>

</style>