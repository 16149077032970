<template>
  <ion-page>

    <main-header />
    
    <ion-content :fullscreen="true" style="position: relative">

        <ion-grid>
          <ion-row>
            <ion-col size="6" v-for="pv in products" :key="pv.id">
              <div style="margin: 0px; padding: 5px; box-shadow: 0px 0px 5px #00000080; border-radius: 20px; height: 100%">
                <div @click="goToProduct(pv)" style="padding: 5px">
                  <img style="border-radius: 20px; height: 150px; width: 100%; object-fit: cover; overflow: hidden" :src="pv.product.image">
                </div>
                <div style="text-align: center">
                    <div style="color: #000098">{{switchLang(pv.product.name_en, pv.product.name_ar)}}</div>
                    <div style="font-size: 14px; color: #575757">{{switchLang(pv.product.description_en, pv.product.description_ar)}}</div>
                    <ion-row>
                        <ion-col size="5">{{pv.price}}KD</ion-col>
                        <ion-col size="5" style="color: #f3cc00;">
                        {{pv.price * 1000}}
                        </ion-col>
                        <ion-col size="2" style="position: relative">
                        <coin style="position: absolute; top:-10px; left:-10px; width: 50px; height: 50px; "/>
                        </ion-col>
                    </ion-row>
                    <ion-row style="display: flex; justify-content: center">
                        <div style="color: #1f2e60" @click="addToWishlist(pv)" v-if="store.state.user.wishlist.includes(pv)"><ion-icon size="large" :icon="heart"></ion-icon></div>
                        <div style="color: #1f2e60" @click="addToWishlist(pv)" v-if="!store.state.user.wishlist.includes(pv)"><ion-icon size="large" :icon="heartOutline"></ion-icon></div>
                    </ion-row>
                  <div></div>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, ref, computed, onMounted } from 'vue'
import {  IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { cart, heart, heartOutline } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';
import Coin from '@/components/elements/toys/CoinIcon.vue'

import MainHeader from '@/views/Admin/Components/MainHeader.vue'

  const slider = ref()

  const en = computed(() => s.lang == 'en')

  const slideNext = async () => { await slider.value.$el.slideNext() }
  setInterval(() => {
    if(slider.value){
      slideNext()
    }
  }, 3000)

  onMounted(()=>{
    if(localStorage.getItem("wishlist")){
      store.state.user.wishlist = JSON.parse(localStorage.getItem("wishlist"))
    }
  })

  const mainCategory = ref('boys')
  const subcategory = ref('cars')

  const products = computed(() => {
    return store.state.user.wishlist
  })

  const segmentChanged = (ev) => {
    mainCategory.value = ev.detail.value
  }

  const switchLang = (en, ar) => {
    if(s.lang == 'en')
    { return en } 
    else if(s.lang == 'ar')
    { return ar }
  }

  const goToProduct = (product) => {
    store.state.activeProduct = product
    router.push('/client/productsVersions/' + product.id)
  }

  const slideOpts = {
    initialSlide: 1,
    speed: 1000,
    loop: true
  }


  const addToWishlist = (product) => {
    if(store.state.user.wishlist.includes(product)){
      store.state.user.wishlist.splice(store.state.user.wishlist.indexOf(product),1)
      localStorage.setItem('wishlist', JSON.stringify(store.state.user.wishlist))
    } else {
      store.state.user.wishlist.push(product)
      localStorage.setItem('wishlist', JSON.stringify(store.state.user.wishlist))
    }
  }

    
</script>

<style scoped>

.fade-in {
  animation: fade-in 5s ease-out forwards;
}

@keyframes fade-in{
  0%{
    opacity: 0
  }
  50%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
}
</style>