import { createStore } from 'vuex'
import router from '@/router/index';
import axios from 'axios'
import { popoverController, toastController, loadingController, alertController  } from '@ionic/vue'

interface Product {
    id: number;
    en: object;
    ar: object;
    type: string;
    image: string;
    price: number;
    categories: Array<string>;
}



const store = createStore({
    state: {
        lang: 'ar',
        intro: true,
        token: '',

        loading: {} as any,

        loggedIn: false,

        toastMessage: '',
        toastColor: '',

        isOnline: navigator.onLine,
        // api: 'http://127.0.0.1:8001/api/',
        api: 'https://storzapi.legioncraftstudios.com/api/',
        // api: 'https://stagingapi.getgoodz.com/api/',
        // api: 'https://api.getgoodz.com/api/',

        headerClass:'header-fade-in',

        categories: [],
        activeCategory: 0,
        activeSubcategory: 0,

        featuredProducts: [],
        joinRequests:[],
        
        user: {
            email: '',
            name: '',
            id: '',
            address: '',
            phone_number: '',
            coins: 10000,
            cart: JSON.parse(localStorage.getItem("cart") as string) || {},
            wishlist: JSON.parse(localStorage.getItem("wishlist") as string) || [],
            orders: JSON.parse(localStorage.getItem("orders") as string) || [],
            role:{
                name:'guest'
            }
        },

        genders: [],
        
        ageRanges: [],

        products: [],
        suppliersProducts: [],
        productsVersions: [],

        adminUsers: [],

        orders:[],

        suppliers: [],

        clients: [],

        activeProduct: {} as Product,
        activeProductVersion: {},
        activeUser: {} ,

        coupons: [
            {
                id: 1, 
                code: 'EX435987', 
                value: 0,
                type: 'bogo',
                descriptionEn: 'Buy any toy and get onother like it on selected items', 
                descriptionAr: 'اشتري منتج و احصل على آخر مثله مجانا على منتجات مختارة', 
                image: require('@/../public/assets/coupons/bogo.png'),
            },
            {
                id: 2, 
                code: 'EX439509', 
                value: 20,
                type: 'discountPercentage',
                descriptionEn: '20% Discount', 
                descriptionAr: 'تخفيض ٢٠٪', 
                image: require('@/../public/assets/coupons/20percentCoupon.jpeg'),
            },
            {
                id: 3, 
                code: 'EX439509', 
                value: 5,
                type: 'discountAmount',
                descriptionEn: '5 KD Voucher', 
                descriptionAr: 'كوبون بقيمة ٥ دك', 
                image: require('@/../public/assets/coupons/5kdVoucher.png'),
            }
        ],

        // // // helpers // // //
        
        productToEdit: {},
        productToEditImage: '',
        editProductFormOpened: false,
        
        productVersionToEdit: {},
        editProductVersionFormOpened: false,

        userToEdit: {},
        userToEditImage: '',
        editUserFormOpened: false,

    },
    getters: {
        getCartItemsCount: state => {
            return Object.values(state.user.cart).reduce((acc,supplier:any)=>acc + supplier.items.reduce((ac:number,item:any) => ac + parseInt(item.quantity),0),0)
        },
        getCartTotal: state => {
            return Object.values(state.user.cart).reduce((acc,supplier:any)=>acc + supplier.items.reduce((ac:number,item:any) => ac + parseInt(item.productVersion.price) * parseInt(item.quantity),0),0)
        }
    },
    mutations: {
    },
    actions: {
        async presentLoading() {
            store.state.loading = await loadingController
              .create({
                cssClass: 'my-custom-class',
                message: 'Please wait...',
              });
    
            await store.state.loading.present();
    
            // setTimeout(function() {
            //   loading.dismiss()
            // }, this.timeout);
        },

        openToast: async (context) => {
            const toast = await toastController
              .create({
                message: context.state.toastMessage,
                color: context.state.toastColor,
                cssClass: 'toast',
                duration: 3000,
                position: 'top',
                buttons: [{
                text: 'X  ',
                    role: 'cancel',
                }]
              })
            return toast.present();
        },

        missingData: (context) => {
            context.state.toastMessage = context.state.lang == 'en' ? 'Please Fill All Required Data' : 'برجاء ادخال جميع المعلومات المطلوبة' 
            context.state.toastColor = 'danger'
            context.dispatch('openToast')
        },
        
        cannotAddDifferentSupplierProductAlert: async (context, data) => {
            const alert = await alertController
              .create({
                cssClass: 'my-custom-class',
                header: context.state.lang == 'en' ? 'Attention!' : 'تنبيه!',
                message: context.state.lang == 'en' ? 'Cannot Add products from different suppliers' : 'لا يمكن اضافة منتجات من موردين مختلفين',
                buttons: [{text: context.state.lang == 'en' ? 'Ok' : 'اوك'}],
              });
            return alert.present();
          },

        login: (context, credentials) => {
            // context.dispatch('presentLoading')
            axios({
              method: 'post',
              url: `${context.state.api}login`,
              data: credentials,
              headers: {'Content-Type': 'multipart/form-data' }
            })
            .then( response => {

                
                //store.state.loading.dismiss()
                if(response.data.message == 'Invalid Credentials') {
                    context.state.toastMessage =  context.state.lang == 'en' ? 'Invalid Credentials' : 'كلمة السر او اسم المستخدم خطأ'
                    context.state.toastColor = 'danger'
                    context.dispatch('openToast')
                    //store.state.loading.dismiss()
                } else {
                context.state.user.email = response.data.user.email;
                context.state.user.name = response.data.user.name
                context.state.user.id = response.data.user.id
                context.state.user.address = response.data.user.address
                context.state.user.phone_number = response.data.user.phone_number
                context.state.user.role = response.data.user.role;
                localStorage.setItem('accessToken', response.data.access_token)
                localStorage.setItem('user', JSON.stringify(context.state.user))
                if( response.data.user.role.name == 'admin') {
                    // store.dispatch('getAdminSuppliers')
                    store.dispatch('getAdminClients')
                    store.dispatch('getAdminOrders')
                    // store.dispatch('getAdminProducts')
                    router.push({name: 'admin dashboard'});
                } else if( response.data.user.role.name == 'accounts manager') {
                    store.dispatch('getAdminSuppliers')
                    store.dispatch('getAdminClients')
                    router.push({name: 'admin suppliers'});
                } else if( response.data.user.role.name == 'product editor') {
                    // store.dispatch('getAdminProducts')
                    router.push({name: 'admin products'});
                } else if(response.data.user.role.name == 'supplier') {
                    router.push({name: 'supplier dashboard'});
                } else if(response.data.user.role.name == 'client') {
                    router.push('/');
                } 
                context.state.toastMessage = 'Welcome!'
                context.state.toastColor = 'success'
                context.dispatch('openToast')
                // //store.state.loading.dismiss()
                context.state.loggedIn = true;
                // context.state.showLoginModal = false
      
      
                // document.body.setAttribute('data-theme', response.data.user.view_mode);
              }
            }).catch(errors => {
              //store.state.loading.dismiss()
              context.state.toastMessage = errors[context.state.lang]
              context.state.toastColor = 'danger'
              context.dispatch('openToast')
            })
        },

        onLoad: (context) => {
        // context.dispatch('presentLoading')
    
        axios({
            method: 'get',
            url:`${store.state.api}categories`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.categories = response.data.data
            // store.state.activeCategory = response.data[0].id
            localStorage.setItem('categories', JSON.stringify(response.data.data))

            // //store.state.loading.dismiss()
    
            
        }).catch((errors) => {
            console.log(errors)
            //store.state.loading.dismiss()
            store.state.toastMessage = errors
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
        })
        axios({
            method: 'get',
            url:`${store.state.api}featuredProducts`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.featuredProducts = response.data.data
            // store.state.activeCategory = response.data[0].id
            // localStorage.setItem('featuredProducts', JSON.stringify(response.data.data))

            // //store.state.loading.dismiss()
    
            
        }).catch((errors) => {
            console.log(errors)
            //store.state.loading.dismiss()
            store.state.toastMessage = errors
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
        })
        },

        getAdminUsers: (context) => {
        // context.dispatch('presentLoading')
    
        axios({
            method: 'get',
            url:`${store.state.api}users`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.adminUsers = response.data.data.data
            localStorage.setItem('adminUsers', JSON.stringify(response.data.data.data))

            // //store.state.loading.dismiss()
    
            
        }).catch((errors) => {
            console.log(errors)
            //store.state.loading.dismiss()
            store.state.toastMessage = errors
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
            })
        },

        getAdminJoinRequests: (context) => {
        // context.dispatch('presentLoading')
    
        axios({
            method: 'get',
            url:`${store.state.api}joinRequests`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.joinRequests = response.data.data.data
            localStorage.setItem('joinRequests', JSON.stringify(response.data.data.data))

            // //store.state.loading.dismiss()
    
            
        }).catch((errors) => {
            console.log(errors)
            //store.state.loading.dismiss()
            store.state.toastMessage = errors
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
            })
        },

        getAdminProducts: (context, data) => {
        // context.dispatch('presentLoading')

        axios({
            method: 'get',
            url:`${store.state.api}products?page=${data.page ? data.page : 1}`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.products = response.data.data
            localStorage.setItem('products', JSON.stringify(response.data.data))

            // //store.state.loading.dismiss()
    
            
        }).catch((errors) => {
            console.log(errors)
            //store.state.loading.dismiss()
            store.state.toastMessage = errors
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
            })
        },

        getSuppliersProducts: (context, subcategory_id) => {
            // context.dispatch('presentLoading')
        
            axios({
                method: 'get',
                url:`${store.state.api}suppliers_products?subcategory_id=${subcategory_id}`,
                headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            }).then(response => {

                store.state.suppliersProducts = response.data.data.data
                localStorage.setItem('suppliersProducts', JSON.stringify(response.data.data.data))

                // //store.state.loading.dismiss()
        
                
            }).catch((errors) => {
                console.log(errors)
                // //store.state.loading.dismiss()
                store.state.toastMessage = errors
                store.state.toastColor = 'danger'
                store.dispatch('openToast')
                })
        },

        getSupplierProductsVersions: (context, page) => {
        // context.dispatch('presentLoading')
    
        axios({
            method: 'get',
            url:`${store.state.api}productVersions?page=${page ? page : 1}`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.productsVersions = response.data.data.data
            localStorage.setItem('productsVersions', JSON.stringify(response.data.data.data))

            // //store.state.loading.dismiss()
            
        }).catch((errors) => {
            console.log(errors)
            // //store.state.loading.dismiss()
            store.state.toastMessage = errors
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
            })
        },

        getClientProductsVersions: (context) => {
        // context.dispatch('presentLoading')
    
        axios({
            method: 'get',
            url:`${store.state.api}client_product_versions`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.productsVersions = response.data.data.data
            localStorage.setItem('productsVersions', JSON.stringify(response.data.data.data))

            // //store.state.loading.dismiss()
            
        }).catch((errors) => {
            console.log(errors)
            // //store.state.loading.dismiss()
            store.state.toastMessage = errors
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
            })
        },

        getClientSupplierProductsVersions: (context, data) => {
        // context.dispatch('presentLoading')
    
        axios({
            method: 'get',
            url:`${store.state.api}client_supplier_product_versions/${data.supplierId}?page=${data.page ? data.page : 1}`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.productsVersions = response.data.data
            console.log(store.state.productsVersions)
            localStorage.setItem('productsVersions', JSON.stringify(response.data.data.data))

            // //store.state.loading.dismiss()
            
        }).catch((errors) => {
            console.log(errors)
            // //store.state.loading.dismiss()
            store.state.toastMessage = errors
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
            })
        },

        getClientProductAndVersions: (context, productId) => {
        // context.dispatch('presentLoading')
    
        axios({
            method: 'get',
            url:`${store.state.api}client_products/${productId}`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.activeProduct = response.data.data

            // //store.state.loading.dismiss()
            
        }).catch((errors) => {
            console.log(errors)
            // //store.state.loading.dismiss()
            store.state.toastMessage = errors
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
            })
        },

        getSupplierProductAndVersions: (context, productId) => {
        // context.dispatch('presentLoading')
    
        axios({
            method: 'get',
            url:`${store.state.api}suppliers_products/${productId}`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.activeProduct = response.data.data

            // //store.state.loading.dismiss()
            
        }).catch((errors) => {
            console.log(errors)
            // //store.state.loading.dismiss()
            store.state.toastMessage = errors
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
            })
        },

        getClientProducts: (context, page) => {
        // context.dispatch('presentLoading')
    
            axios({
                method: 'get',
                url:`${store.state.api}client_products?page=${page ? page : 1}`,
                headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            }).then(response => {

                store.state.products = response.data.data
                localStorage.setItem('products', JSON.stringify(response.data.data))

                // //store.state.loading.dismiss()
                
            }).catch((errors) => {
                console.log(errors)
                // //store.state.loading.dismiss()
                store.state.toastMessage = errors
                store.state.toastColor = 'danger'
                store.dispatch('openToast')
            })
        },

        getClientSubcategoryProducts: (context, data) => {
        // context.dispatch('presentLoading')
    
            axios({
                method: 'get',
                url:`${store.state.api}client_products?page=${data.page ? data.page : 1}&subcategory_id=${data.subcategory_id}`,
                headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            }).then(response => {

                store.state.products = response.data.data
                localStorage.setItem('products', JSON.stringify(response.data.data))

                // //store.state.loading.dismiss()
                
            }).catch((errors) => {
                console.log(errors)
                // //store.state.loading.dismiss()
                store.state.toastMessage = errors
                store.state.toastColor = 'danger'
                store.dispatch('openToast')
            })
        },

        getSupplierSubcategoryProducts: (context, data) => {
        // context.dispatch('presentLoading')
    
            axios({
                method: 'get',
                url:`${store.state.api}suppliers_products?page=${data.page ? data.page : 1}&subcategory_id=${data.subcategory_id}`,
                headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            }).then(response => {

                store.state.products = response.data.data
                localStorage.setItem('products', JSON.stringify(response.data.data))

                // //store.state.loading.dismiss()
                
            }).catch((errors) => {
                console.log(errors)
                // //store.state.loading.dismiss()
                store.state.toastMessage = errors
                store.state.toastColor = 'danger'
                store.dispatch('openToast')
            })
        },
       
        getClientSuppliers: (context) => {
        // context.dispatch('presentLoading')
    
        axios({
            method: 'get',
            url:`${store.state.api}suppliers`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.suppliers = response.data.data.data
            localStorage.setItem('suppliers', JSON.stringify(response.data.data.data))

            // //store.state.loading.dismiss()
            
        }).catch((errors) => {
            console.log(errors)
            // //store.state.loading.dismiss()
            store.state.toastMessage = errors.response.data.error
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
            })
        },

        getClientOrders: (context) => {
        // context.dispatch('presentLoading')
    
        axios({
            method: 'get',
            url:`${store.state.api}orders`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.orders = response.data.data.data
            localStorage.setItem('orders', JSON.stringify(response.data.data.data))

            // //store.state.loading.dismiss()
            
        }).catch((errors) => {
            console.log(errors)
            // //store.state.loading.dismiss()
            store.state.toastMessage = errors
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
            })
        },
        
        getSupplierOrders: (context) => {
        // context.dispatch('presentLoading')
    
        axios({
            method: 'get',
            url:`${store.state.api}supplierOrders`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.orders = response.data.data.data
            localStorage.setItem('orders', JSON.stringify(response.data.data.data))

            // //store.state.loading.dismiss()
            
        }).catch((errors) => {
            console.log(errors)
            // //store.state.loading.dismiss()
            store.state.toastMessage = errors
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
            })
        },
        
        getAdminOrders: (context) => {
        // context.dispatch('presentLoading')
    
        axios({
            method: 'get',
            url:`${store.state.api}orders`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.orders = response.data.data.data
            localStorage.setItem('orders', JSON.stringify(response.data.data.data))

            // //store.state.loading.dismiss()
            
        }).catch((errors) => {
            console.log(errors)
            // //store.state.loading.dismiss()
            store.state.toastMessage = errors.response.data.error
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
            })
        },
        
        getAdminSuppliers: (context) => {
        // context.dispatch('presentLoading')
    
        axios({
            method: 'get',
            url:`${store.state.api}suppliers`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.suppliers = response.data.data.data
            localStorage.setItem('suppliers', JSON.stringify(response.data.data.data))

            // //store.state.loading.dismiss()
            
        }).catch((errors) => {
            console.log(errors)
            //store.state.loading.dismiss()
            store.state.toastMessage = errors.response.data.error
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
            })
        },
        
        getAdminClients: (context) => {
        // context.dispatch('presentLoading')
    
        axios({
            method: 'get',
            url:`${store.state.api}clients`,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        }).then(response => {

            store.state.clients = response.data.data.data
            localStorage.setItem('clients', JSON.stringify(response.data.data.data))

            // //store.state.loading.dismiss()
            
        }).catch((errors) => {
            console.log(errors)
            //store.state.loading.dismiss()
            store.state.toastMessage = errors.response.data.error
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
            })
        },
    },
})

export default store