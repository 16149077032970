<template>
    <ion-col size="6" class="popOver">
        <div style="margin: 0px; box-shadow: 0px 0px 5px #00000080; border-radius:  20px 0px 20px 0px; height: 100%" >
            <div @click="goToProductVersion()" style="">
            <img style="border-radius: 20px 0px 20px 0px; box-shadow: 0px 0px 5px #00000080; height: 150px; width: 100%; object-fit: contain; overflow: hidden" :style="supplier && product.productVersions.filter(pv => pv.supplier.id == store.state.user.id) && product.productVersions.filter(pv => pv.supplier.id == store.state.user.id).length > 0 ? {border: '3px solid lightgreen'} : {border: '3px solid #D16BA5'}" :src="product.image">
            </div>
            <div style="text-align: center;  ">
            <div style="color: #D16BA5">{{ en ? product.name_en : product.name_ar}}</div>
            <div style="font-size: 14px; color: #575757">{{ en ? product.description_en : product.description_ar}}</div>
            <!-- <ion-row style="display: flex; justify-content: center">
                <div style="color: #1f2e60" @click="addToWishlist(pv)" v-if="store.state.user.wishlist.find(p => product.id == pv.id)"><ion-icon size="large" :icon="heart"></ion-icon></div>
                <div style="color: #1f2e60" @click="addToWishlist(pv)" v-if="!store.state.user.wishlist.find(p => product.id == pv.id)"><ion-icon size="large" :icon="heartOutline"></ion-icon></div>
            </ion-row> -->
            </div>
        </div>
    </ion-col>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { computed, ref, defineProps } from 'vue'
import { IonRow, IonCol } from '@ionic/vue';
import store from '@/store/index';
import router from '@/router/index';

    const en = computed(() => s.lang == 'en')
    
    const props = defineProps({
        product: Object,
        path: String,
        supplier: {
            type: Boolean,
            default: false
        }
    })

    const catPopClass = ref()

    if(store.state.intro){
        setTimeout(() => {
            store.state.intro = false
            catPopClass.value = 'popOver'
        }, 1000)
    } else {
            catPopClass.value = 'popOver'
    }

    const goToProductVersion = (pv) => {
    router.push(props.path)
  }

</script>

<style scoped>

    .popOver {
        -webkit-animation: bummer 0.5s;
        animation: bummer 0.5s;
        -webkit-transform: scale(0,0); 
        transform: scale(0,0);
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards; /* Add this so that your modal doesn't 
                                            close after the animation completes */
    }

    @-webkit-keyframes bummer {
      100% {
          -webkit-transform: scale(1,1); 
      }
    }
    
    @keyframes bummer {
        100% {
            transform: scale(1,1); 
        }
    }

</style>