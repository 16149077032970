<template>
  <ion-page>

    <main-header />
    
    <ion-content :fullscreen="true" style="position: relative">

      <ion-grid>
          <ion-row v-if="store.state.orders && store.state.orders.length > 0">
            <ion-col size="12" v-for="order in store.state.orders" :key="order.id">
              <div style="margin: 5px; box-shadow: 0px 0px 5px #00000080; border-radius: 20px; height: 100%">
                <div>

                  <ion-row :dir="en ? 'ltr' : 'rtl'" style="border: 1px solid #190f21; background: #b8fd99; color: white; border-radius: 20px 20px 00px 0px; height: 52px; ">
                    <ion-col size="6" style="color: #336666; font-weight: 700; font-size: 14px; padding-right: 10px">{{order.created_at.slice(0,10)}} <br> {{order.created_at.slice(11,16)}}</ion-col>
                    <ion-col size="6" style="font-weight: 700px; font-size:14px">
                      <div style="color: #336666; font-weight: 700; padding: 5px; border-radius: 50px; border: 1px solid black; text-align: center" :style="[order.status == 'submitted' ? {backgroundColor: 'white'}: {},order.status == 'received' ? {backgroundColor: '#e8ff00'}: {},order.status == 'active' ? {backgroundColor: '#0083ff', color: 'white'}: {},order.status == 'delivered' ? {backgroundColor: '#00da00', color: 'white'}: {}]">{{ en ? order.status : order.status }}</div>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col size="1"></ion-col>
                    <!-- <ion-col size="5" style="color: #000098;display: flex; align-items: center; justify-content: end; font-weight: 700">{{order.product_versions.reduce((acc,pv)=>acc+(pv.price*pv.pivot.quantity),0)}} {{ en ? 'KD' : 'د.ك.' }}</ion-col> -->
                  </ion-row>
                  <ion-row v-for="item in JSON.parse(order.order)" :key="item.id" style="border-top: 1px solid #000098">
                      <ion-col size="2"><div><img style="width: 50px" :src="item.item.image"/></div></ion-col>
                      <ion-col size="5" style="display: flex; align-items: center; color: #000098"><div>{{en ? item.item.name_en : item.item.name_ar}}</div></ion-col>
                      <ion-col size="5" style="display: flex; align-items: center; justify-content: end; color: #1f2e60"><div><span style="color: black; font-size: 14px">{{item.item.price}} X {{ item.quantity }}</span> <br> {{item.item.price * item.quantity }} {{ en ? 'KD' : 'د.ك.' }}</div></ion-col>
                  </ion-row>
                </div>
              </div>
            </ion-col>
          </ion-row>
          <ion-row v-else style="display: flex; justify-content: center; margin-top: 150px; text-align: center; font-weight: 700; font-size: 20px; padding: 30px; color: #1f2e60">
            {{ en ? 'Your orders will appear here' : 'طلباتك ستظهر هنا'}}
          </ion-row>
        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, ref, computed, onMounted } from 'vue'
import {  IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { sync, heart, heartOutline } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';
import axios from 'axios';

import MainHeader from '@/views/Admin/Components/MainHeader.vue'

const en = computed(() => s.lang == 'en')

onMounted(() => {
  store.dispatch('getClientOrders')
})

const showStatusUpdater = ref(false)

const updateOrderStatus = (status, order) => {
  showStatusUpdater.value = false

  const formData = new FormData()

  formData.append("status", status);
  formData.append("_method", 'PATCH');
  
  axios({
      method: 'post',
      url:`${store.state.api}orders/${order.id}`,
      data: formData,
      headers: {
      'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
      'Content-Type': 'application/x-www-form-urlencoded'
      // 'Content-Type': 'multipart/form-data'
      }
  }).then( () => {
      store.state.editProductFormOpened = false
      store.state.toastMessage = 'Order Status Updated!'
      store.state.toastColor = 'success'
      store.dispatch('openToast')
      store.dispatch('getSupplierOrders')
      

      location.reload()
      
  }).catch((errors) => {
      store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
      store.state.toastColor = 'danger'
      store.dispatch('openToast')
  })

}

</script>

<style scoped>

.fade-in {
  animation: fade-in 5s ease-out forwards;
}

@keyframes fade-in{
  0%{
    opacity: 0
  }
  50%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
}
</style>