import { defineStore } from 'pinia'
import { ref, computed, reactive } from 'vue'
import dayjs from 'dayjs'
import axios from 'axios'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
import router from '@/router/index';
import { popoverController, toastController, loadingController  } from '@ionic/vue'
import { peopleCircle, people, peopleOutline, bag, storefront, speedometer, list, heart, pricetags, call, archiveSharp, bookmarkOutline, bookmarkSharp, settings, heartSharp, home, mailSharp, logIn, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, fileTrayFull, fileTrayFullOutline, grid } from 'ionicons/icons';
import store from '@/store/index';


import { useRoute } from 'vue-router';

export const useGlobalStore = defineStore('global', () => {
  const lang = ref('ar')
  
  const route = useRoute()

  const en = computed(() => lang.value == 'en')

  const changeLang = () => {
      if(lang.value == 'en') {
          lang.value = 'ar'
      } else {
          lang.value = 'en'
      }
  }
  
  const l = (en, ar) => {
      if(lang.value == 'en') {
          return en
      } else {
          return ar
      }
  }

  const state = reactive({
      // Initialize Cloud Firestore and get a reference to the service
      
      // api: 'http://127.0.0.1:8001/api/',
      api: 'https://storzapi.legioncraftstudios.com/api/',

      intro: true,

      loggedIn: false,
      profile: {},

      user: {
        email: '',
        name_en: '',
        name_ar: '',
        id: '',
        description_en: '',
        description_ar: '',
        logo: '',
        address: '',
        coordinates: '',
        phone_number: '',
        coins: 10000,
        cart: {},
        wishlist: [],
        orders: [],
        role:{
            name:'guest'
        }
    },

      toastMessage: '',
      toastColor: '',

      category: {},
      product: {},
      
      activeTheme: 
        {id:1, name: 'main', accent1: '#336666', accent2: '#41a98d', accent3: '#b8fd99', accent4: '#ffffff', color1: '336666', color2: 'white'}
        // {id:1, name: 'main', accent1: '#2a044a', accent2: '#0b2e59', accent3: '#0d6759', accent4: '#7ab317', color1: '#2a044a', color2: 'white'}
      ,
      themes: [
        {id:1, name: 'main', accent1: '#336666', accent2: '#41a98d', accent3: '#b8fd99', accent4: '#ffffff', color1: '#336666', color2: 'white'},
      ]
      
  })

  const appPages = computed(() => {
    if(store.state.user && store.state.user.role){
      if(store.state.user.role.name == 'admin'){
        return [
          {
            title: 'Dashboard',
            titleAr: 'لوحة التحكم',
            url: '/admin/dashboard',
            iosIcon: speedometer,
            mdIcon: speedometer
          },
          {
            title: 'Clients',
            titleAr: 'عملاء',
            url: '/admin/clients',
            iosIcon: peopleOutline,
            mdIcon: peopleOutline
          },
          {
            title: 'Products',
            titleAr: 'المنتجات',
            url: '/admin/categories',
            iosIcon: grid,
            mdIcon: grid
          },
          {
            title: 'Orders',
            titleAr: 'الطلبات',
            url: '/admin/orders',
            iosIcon: list,
            mdIcon: list
          },
          {
            title: 'Users',
            titleAr: 'المستخدمين',
            url: '/admin/users',
            iosIcon: peopleCircle,
            mdIcon: peopleCircle
          },
        ]
      } else if(state.user.role.name == 'product editor'){
        return [

        {
            title: 'Categories',
            titleAr: 'الفئات',
            url: '/admin/categories',
            iosIcon: grid,
            mdIcon: grid
          },
         
        ]

      } else if (state.user.role.name == 'client'){
        return [
        {
          title: 'Home',
          titleAr: 'الرئيسية',
          url: '/',
          iosIcon: home,
          mdIcon: home
        },
        {
          title: 'Products',
          titleAr: 'المنتجات',
          url: '/client/categories',
          iosIcon: grid,
          mdIcon: grid
        },
        {
          title: 'Coupons',
          titleAr: 'الكوبونات',
          url: '/client/Coupons',
          iosIcon: pricetags,
          mdIcon: heartSharp
        },
        {
          title: 'Wishlist',
          titleAr: 'التمنيات',
          url: '/client/Wishlist',
          iosIcon: heart,
          mdIcon: heart
        },
        {
          title: 'Orders',
          titleAr: 'الطلبات',
          url: '/client/Orders',
          iosIcon: list,
          mdIcon: list
        },
        // {
        //   title: 'Profile',
        //   titleAr: 'الحساب',
        //   url: '/client/profile',
        //   iosIcon: people,
        //   mdIcon: people
        // },
        {
          title: 'Contact Us',
          titleAr: 'تواصلوا معنا',
          url: '/ContactUs',
          iosIcon: call,
          mdIcon: archiveSharp
        },
        ]
      } else {
        return [
          {
            title: 'Home',
            titleAr: 'الرئيسية',
            url: '/',
            iosIcon: home,
            mdIcon: home
          },
          {
            title: 'Products',
            titleAr: 'المنتجات',
            url: '/client/categories',
            iosIcon: grid,
            mdIcon: grid
          },
          {
            title: 'Login',
            titleAr: 'الدخول',
            url: '/login',
            iosIcon: logIn,
            mdIcon: logIn
          },
          {
            title: 'Register',
            titleAr: 'التسجيل',
            url: '/register',
            iosIcon: logIn,
            mdIcon: paperPlaneSharp
          }
        ]
      }
    } else {
      return [
        {
          title: 'Login',
          titleAr: 'الدخول',
          url: '/login',
          iosIcon: logIn,
          mdIcon: logIn
        },
        {
          title: 'Register',
          titleAr: 'التسجيل',
          url: '/register',
          iosIcon: logIn,
          mdIcon: paperPlaneSharp
        }
      ]
    }
  })

  const presentLoading = async () => {
    state.loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
      });

    await state.loading.present();

    // setTimeout(function() {
    //   loading.dismiss()
    // }, this.timeout);
}

const openToast = async () => {
    const toast = await toastController
      .create({
        message: state.toastMessage,
        color: state.toastColor,
        cssClass: 'toast',
        duration: 3000,
        position: 'top',
        buttons: [{
        text: 'X  ',
            role: 'cancel',
        }]
      })
    return toast.present();
}

const missingData = () => {
    state.toastMessage = state.lang == 'en' ? 'Please Fill All Required Data' : 'برجاء ادخال جميع المعلومات المطلوبة' 
    state.toastColor = 'danger'
    openToast()
}

const getClientCategory = (categoryId) => {
  // context.dispatch('presentLoading')

  axios({
        method: 'get',
        url:`${state.api}clientCategory/${categoryId}`,
        headers: {
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
    }).then(response => {

        state.category = response.data

        // //store.state.loading.dismiss()
        
    }).catch((errors) => {
        console.log(errors)
        // //store.state.loading.dismiss()
        state.toastMessage = errors.response
        state.toastColor = 'danger'
        openToast()
    })
}

const getClientProduct = (productId) => {
  // context.dispatch('presentLoading')

  axios({
        method: 'get',
        url:`${state.api}clientProduct/${productId}`,
        headers: {
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
    }).then(response => {

        state.product = response.data.data

        // //store.state.loading.dismiss()
        
    }).catch((errors) => {
        console.log(errors)
        // //store.state.loading.dismiss()
        state.toastMessage = errors.response
        state.toastColor = 'danger'
        openToast()
    })
}


return { 
  state,
  en,
  openToast,
  missingData,
  changeLang,
  lang,
  appPages,

  getClientCategory,
  getClientProduct,
}
})