<template>
  <ion-page>

    <main-header :title="{en:'Users',ar:'المستخدمين'}"/>

      
    <ion-content :fullscreen="true">
      
      <div v-if="!store.state.editUserFormOpened">
        <ion-grid style="margin-bottom: 20px;">
          <UserCard @click="goToUser(u)" v-for="u in users" :key="u.id" :user="u"/>
        </ion-grid>
      </div>

      <div v-if="store.state.editUserFormOpened">
        <UserForm v-if="store.state.editUserFormOpened" :entryType="{process: 'New'}" roleId="2"/>
      </div>

      <div v-if="!store.state.editUserFormOpened" style="position: fixed; bottom: 0px; right: 20px">
        <div @click="store.state.editUserFormOpened = true" style="color: lightgreen; font-size: 72px;"><ion-icon :icon="addCircle"></ion-icon></div>
      </div>

    </ion-content>
  </ion-page>
</template>
    
<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, onMounted, computed } from 'vue'
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonGrid, IonIcon } from '@ionic/vue'
import { addCircle } from 'ionicons/icons';
import { useRoute } from 'vue-router'

import MainHeader from '@/views/Admin/Components/MainHeader.vue'

import UserCard from './Components/UserCard.vue'
import UserForm from '@/views/Admin/Pages/UserForm.vue'

import store from '@/store/index';
import router from '@/router/index';
    
const switchLang = (en, ar) => {
  if(s.lang == 'en')
  { return en } 
  else if(s.lang == 'ar')
  { return ar }
}

const en = computed(() => s.lang == 'en')

const route = useRoute();

onMounted(() => {
  if(route.meta.type == 'Suppliers'){
    store.dispatch('getAdminSuppliers')
  } else if(route.meta.type == 'Clients') {
    store.dispatch('getAdminClients')
  } else if(route.meta.type == 'Users') {
    store.dispatch('getAdminUsers')
  }
})

const users = computed(() => {
  if(route.meta.type == 'Suppliers'){
    return store.state.suppliers
  } else if(route.meta.type == 'Clients') {
    return store.state.clients
  }else if(route.meta.type == 'Users') {
    return store.state.adminUsers
  } else {
    return []
  }
})

const goToUser = (user) => {
  store.state.activeUser = user
  router.push('/admin/' + route.meta.type.toLowerCase() + '/' + user.id)
}
         
</script>