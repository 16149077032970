<template>
  <ion-page>
    <ion-header :translucent="true" style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
      <ion-toolbar style="--background: #ffffff40">
        <ion-buttons slot="start">
          <ion-back-button text="" color="primary" defaultHref="/supplier/categories"></ion-back-button>
        </ion-buttons>
          <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px; margin-top: 20px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
          <ion-title size="large" style="text-align: center; margin-top: 10px"><div v-if="store.state.activeCategory.subcategories && store.state.activeCategory.subcategories.find(cat => cat.id == route.params.scid)" style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #1f2e60">{{ en ? store.state.activeCategory.subcategories.find(cat => cat.id == route.params.scid).name_en : store.state.activeCategory.subcategories.find(cat => cat.id == route.params.scid).name_ar }}</div></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">

      <transition name="content">
        <div>

        <!-- <ion-grid>
          <ion-row>
            <ion-col>
              <div style="padding: 5px 10px; border-radius: 50px; border: 1px solid black; text-align: center">
                <ion-input v-model="search" placeholder="Search"/>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid> -->

        <div style="padding: 10px">
          <ion-segment @ionChange="changeView($event.detail.value)" :value="currentView">
            <ion-segment-button value="my">
              <ion-label>{{en ? 'My Products' : 'منتجاتي'}}</ion-label>
            </ion-segment-button>
            <ion-segment-button value="all">
              <ion-label>{{en ? 'All Products' : 'جميع المنتجات'}}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </div>
        
          <ion-grid style="margin-bottom: 20px" :dir="s.lang == 'ar' ? 'rtl' : 'ltr'">
            <ion-row v-if="products && products.length > 0" >
              <ProductCard supplier v-for="p in products" :key="p.id" :product="p" :path="'/supplier/productsVersions/' + p.id"/>
            </ion-row>
            <ion-row v-else style="display: flex; justify-content: center; margin-top: 150px; text-align: center; font-weight: 700; font-size: 20px; padding: 30px; color: #1f2e60">
              {{ en ? 'unfortunately there are no products matching your criteria' : 'للأسف لا توجد منتجات الان توافق اختياراتك'}}
            </ion-row>
          </ion-grid>

        <!-- Paginator -->
        <ion-row style="display:flex; justify-content: space-around; margin: 30px; text-align: center; color: #8759a6" v-if="store.state.productsVersions.current_page && store.state.productsVersions.last_page">
          <ion-col size="4" style="cursor: pointer">
            <ion-icon v-if="store.state.productsVersions.current_page != 1" @click="store.dispatch('getClientSubcategoryProducts', {page: store.state.productsVersions.current_page - 1, subcategory_id: route.params.scid})" :icon="arrowBack" size="large"></ion-icon>
          </ion-col>
          <!-- <div class="bg-white" style="box-shadow: 0px 0px 5px 0.1px; border-radius: 100px; padding: 5px 15px; cursor: pointer" :style="[store.state.products.current_page == p ? {border: '2px solid darkblue'} : {}]" v-for="p in store.state.products.last_page > 5 ? 5 : store.state.products.last_page" :key="p" @click="store.dispatch('getClientProducts', store.state.products.current_page > 3 ? p + 1 : p)">{{store.state.products.current_page > 3 ? p + 1 : p}}</div> -->
          <ion-col size="4" style="font-weight: 700; font-size:28px;">{{ store.state.productsVersions.current_page }} / {{ store.state.productsVersions.last_page }}</ion-col>
          <ion-col size="4" style="cursor: pointer">
            <ion-icon v-if="store.state.productsVersions.current_page != store.state.productsVersions.last_page" @click="store.dispatch('getClientSubcategoryProducts', {page: store.state.productsVersions.current_page + 1, subcategory_id: route.params.scid})" :icon="arrowForward" size="large"></ion-icon>
          </ion-col>
        </ion-row>

        </div>
      </transition>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineProps, ref, computed, onMounted } from 'vue'
import { IonButtons, IonContent, IonHeader, IonBackButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon, IonSegment, IonSegmentButton, IonLabel } from '@ionic/vue';
import { cart, heart, heartOutline, arrowBack, arrowForward } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import { useRoute } from 'vue-router'

import router from '@/router/index';
import axios from 'axios'
import ProductCard from '@/views/Components/ProductCard.vue'

  const props = defineProps({
    scid: Number
  })

  const route = useRoute()

  const en = computed(() => s.lang == 'en')

  onMounted(() => {
    store.state.activeCategory = {...store.state.categories.find(cat => cat.id == route.params.cid)}
    store.dispatch('getSupplierSubcategoryProducts', {page: 1, subcategory_id: route.params.scid})
  })

  const search = ref('')

  const currentView = ref('all')
  const changeView = (e) => {
    currentView.value = e
  }

  const products = computed(() => {
    if(currentView.value == 'my'){
      return store.state.products.data.filter(product => product.productVersions.filter(pv => pv.supplier.id == store.state.user.id) && product.productVersions.filter(pv => pv.supplier.id == store.state.user.id).length > 0)
    }else if(search.value == ''){
      return store.state.products.data
    }else{
      return store.state.products.data.filter(pv => {
        return pv.product.name_en.toLowerCase().includes(search.value.toLowerCase()) || 
              pv.product.name_ar.toLowerCase().includes(search.value.toLowerCase())
      })
    }
  })

</script>

<style scoped>

.popOver {
    -webkit-animation: bummer 0.5s;
    animation: bummer 0.5s;
    -webkit-transform: scale(0,0); 
    transform: scale(0,0);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; /* Add this so that your modal doesn't 
                                      close after the animation completes */
}

@-webkit-keyframes bummer {
    100% {
        -webkit-transform: scale(1,1); 
    }
}

@keyframes bummer {
    100% {
        transform: scale(1,1); 
    }
}

.fade-in {
  animation: fade-in 0.3s ease-out forwards;
}
.header-fade-in {
  animation: header-fade-in 4s ease-in forwards;
}

@keyframes header-fade-in{
  0%{
    opacity: 0
  }
  75%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
}
@keyframes fade-in{
  0%{
    opacity: 0
  }
  50%{
    opacity: 0.5
  }
  100%{
    opacity: 1
  }
}
.fade-out {
  animation: fade-out 1s ease-out forwards;
}

@keyframes fade-out{
  0%{
    opacity: 1
  }
  50%{
    opacity: 0.5
  }
  100%{
    opacity: 0
  }
}

  .overlay-enter-active {
    transition: all 1s ease-out;
  }

  .overlay-leave-active {
    transition: all 1s ease-out;
  }

  .overlay-enter-from,
  .overlay-leave-to {
    /* transform: translateY(-100%); */
    opacity: 0;
  }

  .content-enter-active {
    transition: all 2s ease-out;
  }

  .content-leave-active {
    transition: all 2s ease-out;
  }

  .content-enter-from,
  .content-leave-to {
    /* transform: translateY(-100%); */
    opacity: 0;
  }

.header-bg{
  background-image: linear-gradient(to bottom top, #6d327c, #485DA6, #00a1ba, #00BF98, #36C486);
}

.catpill{
  border: 2px solid #6d327c;
  border-radius: 100px;
  text-align: center;
  padding: 5px;

}
</style>