<template>
  <ion-page>
    
    <main-header />
    
    <ion-content :fullscreen="true" style="position: relative">
      <!-- <transition name="overlay">
        <loading v-if="store.state.intro" style="z-index: 99; height: 100%; position: fixed; top: 0px"/>
      </transition> -->

        <ion-grid>
          <ion-row style="padding: 30px 10px 0px 10px; display: flex; justify-content: center;">
            <img style="width: 100px" src="@/../public/assets/images/tj-logo.png" class="popOver"/>
          </ion-row>

          <ion-row style=""  class="popOver">
            <ion-col v-auto-animate >
              <ion-row style=" display: flex; justify-content: center; margin: 0px 0px 0px 0px; padding: 30px 30px 0px 30px">
                <!-- <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && registerForm.join_code == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="registerForm.join_code" :placeholder="switchLang('Join Code', 'كود الانضمام')"></ion-input>
                </ion-col> -->
                <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && registerForm.phone_number == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="registerForm.phone_number" :placeholder="switchLang('Phone Number', 'رقم التليفون')"></ion-input>
                </ion-col>
                <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && registerForm.email == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="registerForm.email" :placeholder="switchLang('Email', 'البريد الالكتروني')"></ion-input>
                </ion-col>
                <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && registerForm.password == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="registerForm.password" :placeholder="switchLang('Password', 'كلمة السر')"></ion-input>
                </ion-col>
                <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && registerForm.password_confirmation == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="registerForm.password_confirmation" :placeholder="switchLang('Password Confirmation', 'تأكيد كلمة السر')"></ion-input>
                </ion-col>
                <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && registerForm.name == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="registerForm.name" :placeholder="switchLang('Name', 'الاسم')"></ion-input>
                </ion-col>
                <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && registerForm.address == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="registerForm.address" :placeholder="switchLang('Address', 'العنوان')"></ion-input>
                </ion-col>
                <!-- <ion-col size="12">
                  <ion-input :style="registerFormValidationErrorShow && registerForm.field == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="registerForm.field" :placeholder="switchLang('Field', 'المجال')"></ion-input>
                </ion-col> -->
              </ion-row>

              <ion-row style="width: 100%">
                <ion-col style="display: flex; justify-content: center; width: 100%">
                    <div @click="register()" style="cursor: pointer; background: #b9fd99; padding: 10px; margin: 10px; border-radius: 20px; width: 200px; text-align: center; font-size: 20px; font-weight: 700">{{switchLang('Register', 'تسجيل')}}</div>
                </ion-col>
              </ion-row>

              <ion-row style="width: 100%">
                  <ion-col style="display: flex; justify-content: center; width: 100%">
                      <div @click="router.push('/login')" style="cursor: pointer; background: #1f2e60; padding: 10px; margin: 10px; border-radius: 20px; width: 200px; text-align: center; font-size: 20px; font-weight: 700; color: white">{{switchLang('Back to Login', 'الرجوع الى الدخول')}}</div>
                  </ion-col>
                </ion-row>

            </ion-col>
          </ion-row>
        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, ref, computed, reactive } from 'vue'
import {  IonInput, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonToolbar, IonGrid, IonRow, IonCol,  } from '@ionic/vue';
import { cart, heart, heartOutline } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';
import axios from 'axios';
import MainHeader from '@/views/Admin/Components/MainHeader.vue'

    const en = computed(() => s.lang == 'en')

    
    setTimeout(() => {
      store.state.intro = false
    }, 3000)

    setTimeout(() => {
      store.state.headerClass = ''
    }, 4000)

    const switchLang = (en, ar) => {
      if(s.lang == 'en')
      { return en } 
      else if(s.lang == 'ar')
      { return ar }
    }

    const registerForm = reactive({
      phone_number: '',
      email: '',
      password: '',
      password_confirmation: '',
      name: '',
      address: '',
    })

    const showJoinRequestSuccess = ref(false)

    const registerFormValidated = () => {
      return registerForm.phone_number != null && registerForm.phone_number != '' &&
      // registerForm.join_code != null && registerForm.join_code != '' &&
      registerForm.email != null && registerForm.email != '' &&
      registerForm.name != null && registerForm.name != '' &&
      registerForm.password != null && registerForm.password != '' &&
      registerForm.password_confirmation != null && registerForm.password_confirmation != '' &&
      registerForm.address != null && registerForm.address != '' 
      // registerForm.field != null && registerForm.field != ''
    }

    const registerFormValidationErrorShow = ref(false)

    const register = () => {
      if(registerFormValidated()){

        const credentials = new FormData()
        credentials.set('phone_number', registerForm.phone_number)
        credentials.set('email', registerForm.email)
        // credentials.set('join_code', registerForm.join_code)
        credentials.set('name_en', registerForm.name)
        credentials.set('name_ar', registerForm.name)
        credentials.set('password', registerForm.password)
        credentials.set('password_confirmation', registerForm.password_confirmation)
        credentials.set('address', registerForm.address)

        axios({
          method: 'post',
          url: `${store.state.api}register`,
          data: credentials,
          headers: {'Content-Type': 'multipart/form-data' }
        })
        .then( response => {
          const credentials = new FormData()
          credentials.set('email', registerForm.email)
          credentials.set('password', registerForm.password)
          // credentials.set('fcmToken', store.state.fcmToken);
          store.dispatch('login', credentials)
          // router.push({name: 'client categories'});

        }).catch(errors => {
          console.log(errors)
          store.state.toastMessage = errors.response.data.message
          store.state.toastColor = 'danger'
          store.dispatch('openToast')
          
        })
      } else {
        store.dispatch('missingData')
        registerFormValidationErrorShow.value = true
      }
    }

    const showJoinRequestForm = ref(false)

</script>

<style scoped>

.header-fade-in {
  animation: header-fade-in 4s ease-in forwards;
}

@keyframes header-fade-in{
  0%{
    opacity: 0
  }
  75%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
}

.pillInput{
  box-shadow: 0px 0px 10px 0px #c0c0c0;
  border-radius: 50px;
  margin: 10px !important;
  text-align: center;
}

.popOver {
      -webkit-animation: bummer 0.5s;
      animation: bummer 0.5s;
      -webkit-transform: scale(0,0); 
      transform: scale(0,0);
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards; /* Add this so that your modal doesn't 
                                        close after the animation completes */
  }
  
  @-webkit-keyframes bummer {
      100% {
          -webkit-transform: scale(1,1); 
      }
  }
  
  @keyframes bummer {
      100% {
          transform: scale(1,1); 
      }
  }

</style>