<template>
    <ion-page>
        <!-- <ion-header :translucent="true"  style="background: #b8fd99;">
          <ion-toolbar style="--background: #ffffff00">
            <ion-buttons slot="start">
              <ion-menu-button style="color: #336666"></ion-menu-button>
            </ion-buttons>
              <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #336666">{{ en ? 'Dashboard' : 'لوحة التحكم' }}</div></ion-title>
            <ion-buttons slot="end" style="position: relative">
            </ion-buttons>
          </ion-toolbar>
        </ion-header> -->
        <main-header :title="{en:'Dashboard',ar:'لوحة التحكم'}"/>
        
        <ion-content :fullscreen="true">
          
        <ion-grid style="margin-bottom: 20px;">
          <ion-row>
              <ion-col size="12" style="position: relative">
                  <div class="card" v-if="store.state.products.data">
                    <div class="card-header">
                      {{en ? 'All Products' : 'جميع المنتجات'}}
                    </div>
                    <div class="card-body">
                      {{ store.state.products.data.length }}
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header">
                      {{en ? 'All Clients' : 'جميع العملاء'}}
                    </div>
                    <div class="card-body">
                      {{ store.state.clients.length }}
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header">
                      {{en ? 'All Orders' : 'جميع الطلبات'}}
                    </div>
                    <div class="card-body">
                      {{ store.state.orders.length }}
                    </div>
                  </div>
              </ion-col>
          </ion-row>
        </ion-grid>
    
        </ion-content>
        </ion-page>
    </template>
    
<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, onMounted, computed } from 'vue'
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonGrid, IonRow, IonCol } from '@ionic/vue'
// import { cart, gift } from 'ionicons/icons';
import MainHeader from '@/views/Admin/Components/MainHeader.vue'
import store from '@/store/index';

const switchLang = (en, ar) => {
  if(s.lang == 'en')
  { return en } 
  else if(s.lang == 'ar')
  { return ar }
}

const en = computed(() => s.lang == 'en')

onMounted(()=>{
  store.dispatch('getAdminClients')
  store.dispatch('getAdminOrders')
  store.dispatch('getAdminProducts',{page: 1})
})
      
</script>

<style scoped>

.card{
  border-radius: 20px; 
  box-shadow: 0px 0px 5px #00000080; 
  padding: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin: 10px;
}

.card-header{
  color: #41a98d;
}

.card-body{
  color: #1f2e60;
}

</style>