<template>
    <div :dir=" en ? 'ltr' : 'rtl'" v-if="Object.keys(store.state.userToEdit).length > 0 || entryType.process == 'New'">

        <ion-row v-if="!route.path.split('/')[2] == 'users'" style="padding: 20px 10px">
            <ion-col size="6" style="text-align: center;" v-if="entryType['process'] == 'Edit' && store.state.userToEdit != null">
                <div style="font-weight: 700; color: #969696" v-if="image.url">{{ en ? 'Current' : 'الحالي' }}</div>
                <ion-img :src="store.state.userToEditImage"></ion-img>
            </ion-col>
            <ion-col size="6" style="text-align: center; display: flex; justify-content: center; align-items: center; border: 1px dotted #969696; border-radius: 20px; flex-wrap: wrap;">
                <div style="font-weight: 700; color: #969696" v-if="image.url">{{ en ? 'New' : 'الجديد' }}</div>
                <ion-img style="width: 100%" v-if="image.url" :src="image.url"></ion-img>
                <button style="background-color: #336666; padding: 10px; border-radius: 50px" id="fileInputButton" onclick="document.getElementById('fileInput').click()">{{ en ? 'Choose New image' : 'اختر صورة جديد' }}</button>
            </ion-col>
        </ion-row>
        <!-- Hidden image input -->
        <input style="display:none" id="fileInput" type="file" @change="imageFile" accept="image/png, image/jpeg">
        <!-- Hidden image input -->

        <ion-item>
            <ion-label position="floating">{{ en ? 'English Name' : 'الاسم الانجليزي' }}</ion-label>
            <ion-input v-model="store.state.userToEdit.name_en"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating">{{ en ? 'Arabic Name' : 'الاسم العربي' }}</ion-label>
            <ion-input v-model="store.state.userToEdit.name_ar"></ion-input>
        </ion-item>

        <ion-item v-if="route.path.split('/')[2] != 'users'">
            <ion-label position="floating">{{ en ? 'English Description' : 'الوصف الانجليزي' }}</ion-label>
            <ion-input v-model="store.state.userToEdit.description_en"></ion-input>
        </ion-item>
        
        <ion-item v-if="route.path.split('/')[2] != 'users'">
            <ion-label position="floating">{{ en ? 'Arabic Description' : 'الوصف العربي' }}</ion-label>
            <ion-input v-model="store.state.userToEdit.description_ar"></ion-input>
        </ion-item>
        
        <ion-item v-if="route.path.split('/')[2] == 'clients'">
            <ion-label position="floating">{{ en ? 'Address' : 'العنوان' }}</ion-label>
            <ion-input v-model="store.state.userToEdit.address"></ion-input>
        </ion-item>
        
        <ion-item v-if="route.path.split('/')[2] == 'clients'">
            <ion-label position="floating">{{ en ? 'Additional Info' : 'معلومات اخرى' }}</ion-label>
            <ion-input v-model="store.state.userToEdit.additional_info"></ion-input>
        </ion-item>

        <ion-item v-if="entryType['process'] == 'New'">
            <ion-label position="floating">{{ en ? 'Email' : 'البريد الالكتروني' }}</ion-label>
            <ion-input v-model="store.state.userToEdit.email"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating">{{ en ? 'Password' : 'كلمة المرور' }}</ion-label>
            <ion-input v-model="store.state.userToEdit.password"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating">{{ en ? 'Password Confirmation' : 'تأكيد كلمة المرور' }}</ion-label>
            <ion-input v-model="store.state.userToEdit.password_confirmation"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating">{{ en ? 'Phone Number' : 'رقم التليفون' }}</ion-label>
            <ion-input v-model="store.state.userToEdit.phone_number"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating">{{ en ? 'Status' : 'الحالة' }}</ion-label>
            <ion-select v-model="store.state.userToEdit.status" :value="store.state.userToEdit.status">
                <ion-select-option value="active">{{en ? 'Active' : 'فعال'}}</ion-select-option>
                <ion-select-option value="inActive">{{en ? 'Inactive' : 'خامل'}}</ion-select-option>
            </ion-select>
        </ion-item>

        <ion-item>
            <ion-label position="floating">{{ en ? 'Role' : 'الدور' }}</ion-label>
            <ion-select v-model="store.state.userToEdit.role_id" :value="store.state.userToEdit.role_id">
                <ion-select-option :value="1">{{en ? 'Admin' : 'ادمن'}}</ion-select-option>
                <ion-select-option :value="2">{{en ? 'Supplier' : 'مورد'}}</ion-select-option>
                <ion-select-option :value="3">{{en ? 'Client' : 'عميل'}}</ion-select-option>
                <ion-select-option :value="4">{{en ? 'Product Editor' : 'محرر منتجات'}}</ion-select-option>
                <ion-select-option :value="5">{{en ? 'Accounts Manager' : 'مدير حسابات'}}</ion-select-option>
            </ion-select>
        </ion-item>

        <ion-item v-if="store.state.userToEdit.role_id == 2">
            <ion-label position="floating">{{ en ? 'Minimum Order' : 'الحد الادنى للطلب' }}</ion-label>
            <ion-input v-model="store.state.userToEdit.minimum"></ion-input>
        </ion-item>

        <ion-row class="ion-justify-content-center ion-padding">
            <div class="actionButton" @click="submitForm()" >{{ en ? entryType['process'] : entryType['process'] == 'Edit' ? 'تحديث' : 'اضافة' }}</div>
            <div class="cancelButton" @click="handleDidDismiss()">{{ en ? 'Cancel' : 'الغاء' }}</div>
        </ion-row>

    </div>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineProps, reactive, onUpdated, computed} from 'vue';
import { IonRow, IonInput, IonItem, IonCol, IonImg, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';
import store from '@/store/index.ts';
import { useRoute } from 'vue-router'
import axios from 'axios'
import { chevronBackCircle } from 'ionicons/icons';


const props = defineProps( [
    'isOpen',
    'entryType',
    'edit',
])


const en = computed(() => s.lang == 'en')

const form = reactive ({
    image: '',
});

const image = reactive({
    url: null
})

const imageFile = (img) => {
    store.state.userToEdit.logo = img.target.files[0]

    const file = img.target.files[0]
    image.url = URL.createObjectURL(file)
    // store.state.users.find(p => p.id == parseInt(route.params.id, 10)).image = image.url
}


const handleDidDismiss = () => {
    store.state.editUserFormOpened = false
    store.state.userToEdit.name_en = ''
    store.state.userToEdit.name_ar = ''
    store.state.userToEdit.description_en = ''
    store.state.userToEdit.description_ar = ''
    store.state.userToEdit.image = ''
    store.state.userToEdit.address = ''
    image.url = null

    store.state.userToEdit = {}
}


const validated = () => {
    if(route.path.split('/')[2] == 'users'){
        return store.state.userToEdit.name_en != null && store.state.userToEdit.name_en != '' &&
        store.state.userToEdit.name_ar != null && store.state.userToEdit.name_ar != '' &&
        store.state.userToEdit.email != null && store.state.userToEdit.email != '' &&
        store.state.userToEdit.phone_number != null && store.state.userToEdit.phone_number != '' &&
        store.state.userToEdit.role_id != null && store.state.userToEdit.role_id != '' &&
        store.state.userToEdit.status != null && store.state.userToEdit.status != ''
    } else {
        return store.state.userToEdit.name_en != null && store.state.userToEdit.name_en != '' &&
        store.state.userToEdit.name_ar != null && store.state.userToEdit.name_ar != '' &&
        store.state.userToEdit.description_en != null && store.state.userToEdit.description_en != '' &&
        store.state.userToEdit.description_ar != null && store.state.userToEdit.description_ar != ''&&
        store.state.userToEdit.email != null && store.state.userToEdit.email != '' &&
        store.state.userToEdit.phone_number != null && store.state.userToEdit.phone_number != '' &&
        store.state.userToEdit.role_id != null && store.state.userToEdit.role_id != '' &&
        store.state.userToEdit.status != null && store.state.userToEdit.status != ''
    }
}

const route = useRoute()

const userType = computed(()=> {
    if(route.path.split('/')[2] == 'suppliers'){
        return 2
    } else if(route.path.split('/')[2] == 'clients'){
        return 3
    } else if(route.path.split('/')[2] == 'users'){
        return 1
    } else {
        return 0
    }
    }
)

const createItem = () => {

    if(validated()){

        const formData = new FormData()

        Object.keys(store.state.userToEdit).map(e => {
            formData.append(e, store.state.userToEdit[e])
        })
        if(!route.path.split('/')[2] == 'users'){
            formData.append("role_id", userType.value);
        }
        
        axios({
            method: 'post',
            url:`${store.state.api}users`,
            data: formData,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
            'Content-Type': 'application/x-www-form-urlencoded'
            // 'Content-Type': 'multipart/form-data'
            }
        }).then( () => {
            store.state.toastMessage = 'User Created'
            store.state.toastColor = 'success'
            store.dispatch('openToast')
            store.dispatch('getAdminSuppliers')
            store.dispatch('getAdminClients')
            store.dispatch('getAdminUsers')                    

            store.state.editUserFormOpened = false

        }).catch((errors) => {
            console.log(errors)
            store.state.toastMessage = errors.response.data.message
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
        })
            
    } else {
        store.dispatch('missingData')
    }

}

const updateItem = () => {

    if(validated()){

        const formData = new FormData()

        Object.keys(store.state.userToEdit).map(e => {
            formData.append(e, store.state.userToEdit[e])
        })
        formData.append("_method", 'PATCH');
        if(!route.path.split('/')[2] == 'users'){
            formData.append("role_id", userType.value);
        }
        formData.delete("email");
        
        axios({
            method: 'post',
            url:`${store.state.api}users/${store.state.userToEdit.id}`,
            data: formData,
            headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
            'Content-Type': 'application/x-www-form-urlencoded'
            // 'Content-Type': 'multipart/form-data'
            }
        }).then( () => {
            store.state.editUserFormOpened = false
            store.state.toastMessage = 'User Updated!'
            store.state.toastColor = 'success'
            store.dispatch('openToast')
            store.dispatch('getAdminSuppliers')
            store.dispatch('getAdminClients')
            store.dispatch('getAdminUsers')

            // location.reload()
            
        }).catch((errors) => {
            store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
            store.state.toastColor = 'danger'
            store.dispatch('openToast')
        })

    } else {
        store.dispatch('missingData')
    }
}

const submitForm = () => {
    if (props.entryType['process'] == 'New') {
        createItem()
    }

    else if (props.entryType['process'] == 'Edit') {
        updateItem()
    }
}

onUpdated(() => {

    if (store.state.userToEdit && props.entryType['process'] == 'Edit') {

        form.nameEn = store.state.userToEdit.nameEn      
        form.nameAr = store.state.userToEdit.nameAr      
        form.image = store.state.userToEdit.image
        form.descEn = store.state.userToEdit.descEn
        form.descAr = store.state.userToEdit.descAr
        form.phone = store.state.userToEdit.phone
        form.email = store.state.userToEdit.email
    
    }
})
console.log(userType.value)

</script>

<style scoped>

    ion-content{
    width: calc(100% + 15px)
    }
    ion-content::part(scroll){
    padding-right: 15px
    }

    .clickable {
        pointer-events: auto !important;
    }

    .actionButton {
        margin: 10px;
        padding: 10px;
        border: 1px solid greenyellow;
        border-radius: 100px;
        width: 100px;
        text-align: center;
    }

    .cancelButton {
        margin: 10px;
        padding: 10px;
        border: 1px solid red;
        border-radius: 100px;
        width: 100px;
        text-align: center;
    }
    .bigH {
        margin-top:0px; 
        text-align: center; 
        font-size: 40px;  
        text-shadow: 1px 1px 5px #000000;
    }

    .label-floating.sc-ion-label-ios-h{
    --color: #336666;
    font-weight: 700
    }
</style>