<template>
  <ion-page>
    <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
      <ion-toolbar style="--background: #ffffff00">
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
          <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
          <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #1f2e60">Storz</div></ion-title>
        <ion-buttons slot="end" style="position: relative">
          <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 0px; right: 30px; padding: 0px 5px; z-index: 9; background: lightgreen; height: 20px; border-radius: 50px; text-align: center; font-size: 16px;">{{ store.getters.getCartItemsCount }}</div>
            <div v-if="Object.keys(store.state.user.cart).length > 0" style="position: absolute; top: 18px; right: 30px; padding: 0px 7px 0px 7px; z-index: 9; background: lightblue; height: 22px; border-radius: 50px; text-align: center; font-size: 16px"><span style="color:white" :dir="en ? 'ltr' : 'rtl'">{{en ? 'KD' : 'د.ك.'}}</span>{{ store.getters.getCartTotal }}</div>
          <ion-icon @click="router.push('/checkout')" size="large" :icon="cart" color="primary"></ion-icon>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true" style="position: relative">

        <ion-grid style="height: 100%">
          <ion-row >
              <ion-col style="height: 100%">
                <ion-col size="12" style="display: flex; justify-content: center">
                    <!-- <img style="width: 100px; " src="@/../public/assets/images/visa.png"/> -->
                </ion-col>
                <ion-col size="12" style="display: flex; justify-content: center;">
                    <!-- <img style="width: 100px; " src="@/../public/assets/images/mastercard.jpg"/> -->
                </ion-col>
              </ion-col>
          </ion-row>
            <ion-row>
              <ion-col style="display: flex; justify-content: center">
                  <div @click="pay" style="background: lightgreen; padding: 10px; margin: 10px; border-radius: 20px; width: 200px; text-align: center; font-size: 20px">Pay</div>
              </ion-col>
          </ion-row>
        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, ref, computed } from 'vue'
import {  IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { cart, heart, heartOutline } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';
import axios from 'axios';

const switchLang = (en, ar) => {
  if(s.lang == 'en')
  { return en } 
  else if(s.lang == 'ar')
  { return ar }
}

const pay = () => {

  const formData = new FormData()

  formData.append('supplier_id', store.state.user.cart[0].product.user_id)
  formData.append('products', JSON.stringify(store.state.user.cart))
  
  axios({
      method: 'post',
      url:`${store.state.api}orders`,
      data: formData,
      headers: {
      'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
      'Content-Type': 'application/x-www-form-urlencoded'
      // 'Content-Type': 'multipart/form-data'
      }
  }).then( () => {
      store.state.toastMessage = 'Product Version Created'
      store.state.toastColor = 'success'
      store.dispatch('openToast')
      store.dispatch('getClientProductsVersions')
      store.dispatch('getClientOrders')
      
      // store.state.user.cart = []

      // router.push('/')

  }).catch((errors) => {
      store.state.toastMessage = errors.response.data.errors[Object.keys(errors.response.data.errors)[0]][0]
      store.state.toastColor = 'danger'
      store.dispatch('openToast')
  })
  
}

</script>

<style scoped>

</style>