<template>
  <ion-page>

    <main-header />

    <ion-content :fullscreen="true" style="position: relative">
      <!-- <transition name="overlay">
        <loading v-if="store.state.intro" style="z-index: 99; height: 100%; position: fixed; top: 0px"/>
      </transition> -->

        <ion-grid>
          <ion-row style="padding: 100px 10px 0px 10px; display: flex; justify-content: center;">
            <img style="width: 100px" src="@/../public/assets/images/tj-logo.png" class="popOver"/>
          </ion-row>
          <!-- <ion-row style="padding: 0px 20px">
            <ion-segment @ionChange="s.lang = $event.detail.value" value="ar">
              <ion-segment-button value="en" style="--background-checked: #1f2e60; --indicator-color: #000098; --color: white; font-weight: 700">
                <ion-label>English</ion-label>
              </ion-segment-button>
              <ion-segment-button value="ar" style="--background-checked: #1f2e60; --indicator-color: #000098; --color: white; font-weight: 700">
                <ion-label>العربية</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-row> -->

          <ion-row style=""  class="popOver">
            <ion-col v-auto-animate >
              <ion-row v-if="!showJoinRequestForm" style=" display: flex; justify-content: center; margin: 50px 0px 0px 0px; padding: 30px 30px 0px 30px">
                <ion-col size="12">
                  <ion-input type="email" :style="validationErrorShow && form.email == '' ? {color: 'red'} : {}" class="pillInput" v-model="form.email" dir="ltr" :placeholder="switchLang('Email / Phone Number', 'البريد الالكتروني / التليفون')"></ion-input>
                </ion-col>
                <ion-col size="12">
                  <ion-input type="password" :style="validationErrorShow && form.password == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="form.password" :placeholder="switchLang('Password', 'كلمة السر')"></ion-input>
                </ion-col>
              </ion-row>
              <ion-row v-if="showJoinRequestForm && !showJoinRequestSuccess" style=" display: flex; justify-content: center; margin: 50px 0px 0px 0px; padding: 30px 30px 0px 30px">
                <ion-col size="12">
                    <ion-input :style="joinFormValidationErrorShow && joinForm.number == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="joinForm.number" :placeholder="switchLang('Phone Number', 'رقم التليفون')"></ion-input>
                  </ion-col>
                <ion-col size="12">
                    <ion-input :style="joinFormValidationErrorShow && joinForm.name == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="joinForm.name" :placeholder="switchLang('Name', 'الاسم')"></ion-input>
                  </ion-col>
                <ion-col size="12">
                    <ion-input :style="joinFormValidationErrorShow && joinForm.address == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="joinForm.address" :placeholder="switchLang('Address', 'العنوان')"></ion-input>
                  </ion-col>
                <ion-col size="12">
                    <ion-input :style="joinFormValidationErrorShow && joinForm.field == '' ? {color: 'red'} : {}" class="pillInput" dir="ltr" v-model="joinForm.field" :placeholder="switchLang('Field', 'المجال')"></ion-input>
                  </ion-col>
              </ion-row>
                <ion-row  v-if="!showJoinRequestForm" style="width: 100%">
                  <ion-col style="display: flex; justify-content: center; width: 100%">
                      <div @click="login()" style="cursor: pointer; background: #b9fd99; padding: 10px; margin: 10px; border-radius: 20px; width: 200px; text-align: center; font-size: 20px; font-weight: 700">{{switchLang('Login', 'دخول')}}</div>
                  </ion-col>
                </ion-row>
                
                <ion-row v-if="showJoinRequestForm && !showJoinRequestSuccess" style="width: 100%">
                  <ion-col style="display: flex; justify-content: center; width: 100%">
                      <div @click="join()" style="cursor: pointer; background: #b9fd99; padding: 10px; margin: 10px; border-radius: 20px; width: 200px; text-align: center; font-size: 20px; font-weight: 700">{{switchLang('Join', 'انضمام')}}</div>
                  </ion-col>
                </ion-row>

                <ion-row v-if="showJoinRequestSuccess && showJoinRequestForm" style="width: 100%; margin-top:100px">
                  <ion-col style="display: flex; justify-content: center; width: 100%; font-weight: 700; font-size: 32px; text-align: center; color: #8759a6" :dir="s.lang == 'ar' ? 'rtl' : 'ltr'">
                      {{ switchLang('Thank you for submitting a join request, we will contact you ASAP!', 'شكرا لتقديمك طلب الانضمام، سيتم التواصل معك في اسرع وقت!') }}
                  </ion-col>
                </ion-row>

                <!-- <ion-row style="width: 100%">
                  <ion-col style="display: flex; justify-content: center; width: 100%">
                      <div @click="showJoinRequestForm = !showJoinRequestForm" style="background: #8759a6; padding: 10px; margin: 10px; border-radius: 20px; width: 200px; text-align: center; font-size: 20px; font-weight: 700; color: white">{{!showJoinRequestForm ? switchLang('Submit a join request', 'تقديم طلب انضمام') : switchLang('Back to Login', 'الرجوع الى الدخول')}}</div>
                  </ion-col>
                </ion-row> -->

                <ion-row  v-if="!showJoinRequestForm" style="width: 100%">
                  <ion-col style="display: flex; justify-content: center; width: 100%">
                      <div @click="router.push('/register')" style="cursor: pointer; background: #1f2e60; padding: 10px; margin: 10px; border-radius: 20px; width: 200px; text-align: center; font-size: 20px; font-weight: 700; color: white">{{switchLang('Register', 'تسجيل')}}</div>
                  </ion-col>
                </ion-row>

            </ion-col>
          </ion-row>
        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, ref, computed, reactive } from 'vue'
import {  IonInput, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonToolbar, IonGrid, IonRow, IonCol,  } from '@ionic/vue';
import { cart, heart, heartOutline } from 'ionicons/icons';
// import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';
import axios from 'axios';
import MainHeader from '@/views/Admin/Components/MainHeader.vue'


    const en = computed(() => s.lang == 'en')

    setTimeout(() => {
      store.state.intro = false
    }, 3000)

    setTimeout(() => {
      store.state.headerClass = ''
    }, 4000)

    const switchLang = (en, ar) => {
      if(s.lang == 'en')
      { return en } 
      else if(s.lang == 'ar')
      { return ar }
    }

    const form = reactive({
      email: '',
      password: '',
    })

    const joinForm = reactive({
      number: '',
      name: '',
      address: '',
      field: '',
    })

    const formValidated = () => {
      return form.email != null && form.email != '' &&
      form.password != null && form.password != ''
    }

    const validationErrorShow = ref(false)

    const login = () => {
      if(formValidated()){
        // pushInit()
        const credentials = new FormData()
        credentials.set('email', form.email)
        credentials.set('password', form.password)
        // credentials.set('fcmToken', store.state.fcmToken);
        store.dispatch('login', credentials)
        // s.login(credentials)
      } else {
        s.missingData()
        validationErrorShow.value = true
      }
    }

    const showJoinRequestSuccess = ref(false)

    const joinFormValidated = () => {
      return joinForm.number != null && joinForm.number != '' &&
      joinForm.name != null && joinForm.name != '' &&
      joinForm.address != null && joinForm.address != '' &&
      joinForm.field != null && joinForm.field != ''
    }

    const joinFormValidationErrorShow = ref(false)

    const join = () => {
      if(joinFormValidated()){
        axios({
          method: 'post',
          url: `${store.state.api}joinRequest`,
          data: joinForm,
          headers: {'Content-Type': 'multipart/form-data' }
        })
        .then( response => {
          showJoinRequestSuccess.value = true
          console.log(response)

        }).catch(error => {
          console.log('error')
          
        })
      } else {
        store.dispatch('missingData')
        joinFormValidationErrorShow.value = true
      }
    }

    const showJoinRequestForm = ref(false)

</script>

<style scoped>

.header-fade-in {
  animation: header-fade-in 4s ease-in forwards;
}

@keyframes header-fade-in{
  0%{
    opacity: 0
  }
  75%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
}

.pillInput{
  box-shadow: 0px 0px 10px 0px #c0c0c0;
  border-radius: 50px;
  margin: 10px !important;
  text-align: center;
}

.popOver {
      -webkit-animation: bummer 0.5s;
      animation: bummer 0.5s;
      -webkit-transform: scale(0,0); 
      transform: scale(0,0);
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards; /* Add this so that your modal doesn't 
                                        close after the animation completes */
  }
  
  @-webkit-keyframes bummer {
      100% {
          -webkit-transform: scale(1,1); 
      }
  }
  
  @keyframes bummer {
      100% {
          transform: scale(1,1); 
      }
  }

</style>