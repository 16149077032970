<template>
  <ion-page>
    <ion-header :translucent="true"  style="background-image: linear-gradient(to top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);">
      <ion-toolbar style="--background: #ffffff00">
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
          <!-- <ion-title size="large" style="text-align: center; margin-top: 10px"><img style="width: 50px" src="@/../public/assets/images/101_logo_ideas_2.png"/></ion-title> -->
          <ion-title size="large" style="text-align: center; margin-top: 10px"><div style="width:100%; text-align: center; font-size: 32px; font-weight: 700; color: #1f2e60">{{ en ? 'Suppliers' : 'الموردين' }}</div></ion-title>
        <ion-buttons slot="end" style="position: relative">
          <ion-icon @click="store.dispatch('getClientSuppliers')" size="large" :icon="sync" color="primary"></ion-icon>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
      
    <ion-content :fullscreen="true">
      
      <div>
        <ion-grid v-if="users && users.length > 0" style="margin-bottom: 20px;">
          <ion-row>
            <UserCard @click="goToUser(u)" v-for="u in users" :key="u.id" :user="u"/>
          </ion-row>
        </ion-grid>
        <ion-row v-else style="display: flex; justify-content: center; margin-top: 150px; text-align: center; font-weight: 700; font-size: 20px; padding: 30px; color: #1f2e60">
          {{ en ? 'unfortunately there are no suppliers currently' : 'للأسف لا يوجد موردين الان '}}
        </ion-row>
      </div>

    </ion-content>
  </ion-page>
</template>
    
<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, onMounted, computed } from 'vue'
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonGrid, IonIcon, IonRow } from '@ionic/vue'
import { sync } from 'ionicons/icons';
import { useRoute } from 'vue-router'

import UserCard from './Components/UserCard.vue'

import store from '@/store/index';
import router from '@/router/index';

const switchLang = (en, ar) => {
  if(s.lang == 'en')
  { return en } 
  else if(s.lang == 'ar')
  { return ar }
}

const en = computed(() => s.lang == 'en')

const route = useRoute();

onMounted(() => {
  store.dispatch('getClientSuppliers')
  store.dispatch('getClientProductsVersions')
})

const users = computed(() => {
  return store.state.suppliers
})

const goToUser = (user) => {
  store.state.activeUser = user
  router.push('/client/suppliers/' + user.id)
}
     
</script>