<template>
    <ion-page>

      <main-header :title="{en:'Categories',ar:'الفئات'}"/>
  
      <ion-content :fullscreen="true">

        <ion-grid v-if="!store.state.editProductFormOpened">
          <ion-row>
            <ion-col size="6" sizeMd="3" v-for="c in store.state.categories" :key="c.id" >
              <div @click="router.push(`/admin/category/${c.id}`)" style="cursor: pointer;">
                <div>                  
                  <img style="width: 100%; border-radius: 20px; " class="imgHvr" :src="c.image"/>
                </div>
                <div>
                  <div style="text-align: center; color: #000098; font-size: 14px; white-space: nowrap;">{{en ? c.name_en : c.name_ar}}</div>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>

        <div v-if="store.state.editProductFormOpened">
          <ProductForm v-if="store.state.editProductFormOpened" :entryType="{process: 'New'}"/>
        </div>

        <div v-if="!store.state.editProductFormOpened" style="position: fixed; bottom: 0px; right: 20px">
          <div @click="store.state.editProductFormOpened = true" style="cursor: pointer ;color: lightgreen; font-size: 72px;"><ion-icon :icon="addCircle"></ion-icon></div>
        </div>
      </ion-content>
    </ion-page>
  </template>
  
<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, ref, computed, onMounted } from 'vue'
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { sync, heart, heartOutline, arrowBack, arrowForward, addCircle } from 'ionicons/icons';
import Loading from '../Loading.vue'
import store from '@/store/index';
import router from '@/router/index';
import axios from 'axios'
import ProductForm from '@/views/Admin/Pages/ProductForm.vue'
import CategoryButton from '@/views/Components/CategoryButton.vue'
import Search from '@/views/Components/Search.vue'

import MainHeader from '@/views/Admin/Components/MainHeader.vue'

const en = computed(() => s.lang == 'en')

</script>
  
<style scoped>

  .header-fade-in {
  animation: header-fade-in 2s ease-in forwards;
  }

  @keyframes header-fade-in{
  0%{
    opacity: 0
  }
  75%{
    opacity: 0
  }
  100%{
    opacity: 1
  }
  }

</style>