<template>
    <ion-col size="6" :dir="en ? 'ltr' : 'rtl'" style="padding: 10px 5px">
        <div class="card" :dir="en ? 'ltr' : 'rtl'" style="display: flex; justify-content: center; flex-wrap: wrap">
            <div>
                <img v-if="user.logo" :src="user.logo" style="height: 100px; object-fit:contain; border-radius: 20px 0px 20px 0px;"/>
            </div>
            <div style="width: 100%; text-align: center; border: 1px solid #190f21; background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1); color: white; text-shadow: 0px 0px 5px #000; border-radius: 20px 0px 20px 0px; ">{{ `${en ? user.name_en : user.name_ar}` }} </div>
        </div>
    </ion-col>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineProps, onMounted, computed } from 'vue'
import { IonCol } from '@ionic/vue'
import store from '@/store/index';

    const en = computed(() => s.lang == 'en')
            
    const props = defineProps({
        user: Object,
    })
</script>

<style scoped>
.card{
    border-radius: 20px 0px 20px 0px; 
    box-shadow: 0px 0px 5px #00000080; 
    margin: 5px;
    height: 100%;
    /* font-weight: 700; */
}

.card-header{
    color: #41a98d;
    font-size: 32px;
}

.card-body{
    color: #1f2e60;
}
</style>