<template>
  <ion-page>

    <main-header :title="{en: 'Checkout',ar: 'الدفع'}"/>
    
    <ion-content :fullscreen="true"  :dir="s.lang == 'ar' ? 'rtl' : 'ltr'">

      <div v-if="Object.keys(s.state.user.cart).length > 0" v-auto-animate>

        <ion-row>
          <ion-col size="12" sizeMd="6" style="padding:20px">
            <div style="border-bottom: 2px solid #d2d2d2; position: relative; font-size: 16px" v-for="cartItem in s.state.user.cart" :key="cartItem.id">
              <ion-row style="padding:10px 10px 0px 10px">
                <ion-col size="1">
                  <ion-icon @click="removeItem(cartItem)" :icon="trashOutline" style="position: absolute; right: 10px; top: 10px; font-size: 20px; z-index: 9"></ion-icon>
                </ion-col>
                  <ion-col size="2">
                    <div style="padding: 5px;  width:50px">
                    <img style="border-radius: 20px;" :src="cartItem.item.image">
                    </div>
                  </ion-col>
                  <ion-col size="5">
                    <ion-row>
                      <div style="text-align: center; color: #1f2e60; font-weight: 700">{{switchLang(cartItem.item.name_en, cartItem.item.name_ar)}}</div>
                    </ion-row>
                    <ion-row style="">
                      <div style="text-align: center; color: #575757; ">{{switchLang(cartItem.item.description_en, cartItem.item.description_ar)}}</div>
                    </ion-row>
                    <ion-row style="">
                      <div style="text-align: center; color: black; ">{{switchLang(cartItem.item.price + ' KD', `${cartItem.item.price}ج`)}}</div>
                    </ion-row>
                  </ion-col>
                  <ion-col size="4">
                    <ion-row>
                      <ion-col><div style="border: 1px solid #1f2e60; border-radius: 20px; text-align: center;font-weight: 700; padding: 2px 7px; color: #000098">{{switchLang(cartItem.quantity * cartItem.item.price + 'KD', cartItem.quantity * cartItem.item.price  + 'د.ك.')}}</div></ion-col>
                    </ion-row>
                    <ion-row>
                      <ion-col style="display: flex; align-items: center">
                        <div>{{ switchLang('Quantity', 'الكميه') }}</div>
                        <div style="width: 100px">
                          <ion-select v-model="s.state.user.cart[cartItem.item.id].quantity">
                            <ion-select-option v-for="i in 10" :key="i" :value="i" style="text-align: center">{{ i }}</ion-select-option>
                          </ion-select>
                        </div>
                      </ion-col>                  
                    </ion-row>
                  </ion-col>
                </ion-row>
            </div>
          </ion-col>
          <ion-col size="12" sizeMd="6" style="padding:20px">
            <!-- <ion-row :style="total != finalTotal ? {textDecoration: 'line-through', color: 'gray'} : {}" style="display: flex; justify-content: center; font-size: 20px"> -->
            <ion-row style="display: flex; justify-content: center; font-size: 20px; font-weight: 700; color: #41a98d; padding: 20px 0px">
                {{switchLang('Total: ', 'المجموع: ')}} <span style="color: #336666">{{ total() }} {{ switchLang('KD', 'د.ك.') }}</span>
            </ion-row>
            <!-- <ion-row v-if="total != finalTotal" style="display: flex; justify-content: center; font-size: 20px">
                <div style="border: 3px solid #1f2e60; border-radius: 20px; color: #000098; padding: 5px 7px">{{switchLang('Total after Discounts ' + finalTotal + 'KD', 'المجموع بعد الخصم: ' + finalTotal + ' دك')}}</div>
            </ion-row>
            <ion-row style="display: flex; justify-content: center; font-size: 40px; margin-top:20px; color: #1f2e60">
                {{switchLang('Apply Coupon', 'استخدم كوبون')}}
            </ion-row>
            <ion-row>
              <ion-col size="4" v-for="coupon in store.state.coupons" :key="coupon.id">
                  <div @click="applyCoupon(coupon)" style="border: 2px solid; border-radius: 20px; height: 65px">
                      <img :style="appliedCoupon.id == coupon.id ? {opacity: '0.2'} : {}" style="border-radius: 20px; height: 100%; " :src="coupon.image"/>
                  </div>
              </ion-col>
            </ion-row>
            <ion-row style="display: flex; justify-content: center; font-size: 40px; margin-top:20px; color: #1f2e60">
                {{switchLang('Redeem Points', 'استخدم الكوينز')}}
            </ion-row>
            <ion-row style="display: flex; justify-content: center; items-align: center; font-size: 24px; margin-top:20px">
              <ion-col size="6">
                <div style="padding: 15px; display: flex; justify-content: start">
                  <ion-input style="color: #000098; border-radius: 50px; box-shadow: 0px 0px 5px #00000080; width: 100px; text-align: center" :style="[s.lang == 'en' ? {textAlign: 'right'} : {textAlign: 'left'} ,coinsUsed > store.state.user.coins ? {color: 'red'} : {}]" v-model="coinsUsed"></ion-input>
                </div>
              </ion-col>
              <ion-col size="6">

                <div style="display: flex; align-items: center">
                  <div style="display: flex; color: #f3cc00; font-size: 24px">{{store.state.user.coins - coinsUsed}}</div>
                  <div>
                    <coin style="width: 75px; height: 75px" />
                  </div>
                </div>
              </ion-col>
            </ion-row> -->

            <ion-row>
              <ion-col size="12">
                <div style="font-weight: 700; font-size: 24px; color: #41a98d">{{en ? 'Payment Methods' : 'وسيلة الدفع'}}:<br> <span style="color: #336666">{{ en ? 'Cash on delivery' : 'الدفع عند الاستلام' }}</span></div>
              </ion-col>
              <!-- <ion-col size="12">
                <ion-item lines="none">
                  <ion-checkbox slot="start" checked="true" disabled="true"></ion-checkbox>
                  <ion-label>{{ en ? 'Cash on delivery' : 'الدفع عند الاستلام' }}</ion-label>
                </ion-item>
              </ion-col> -->
            </ion-row>

            <ion-row>
                <ion-col style="display: flex; justify-content: center">
                    <ion-button v-if="store.state.loggedIn && store.state.user.role.name == 'client'" @click="pay()" style="color: lightgreen; padding: 10px; margin: 10px; --border-radius: 100px; width: 200px; text-align: center; font-size: 32px; height: 70px">{{switchLang('Order', 'اطلب')}}</ion-button>
                    <ion-button v-else @click="router.push('/login')" style="color: lightgreen; padding: 10px; margin: 10px; --border-radius: 100px; width: 200px; text-align: center; font-size: 32px; height: 70px">{{switchLang('Login', 'دخول')}}</ion-button>
                </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        </div>

        <ion-grid v-else  style="height: 100%;">

              <div style="height: 100%; display: flex; align-items: center; justify-content: center; font-size: 32px">
                {{switchLang('Shopping Bag is Empty', 'سلة المشتريات فارغة')}}
              </div>

        </ion-grid>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { useGlobalStore } from '@/stores/global'
const s = useGlobalStore()
import { defineComponent, ref, computed } from 'vue'
import { IonInput, IonButtons, IonContent, IonHeader, IonBackButton, IonPage, IonTitle, IonToolbar, IonGrid, IonRow, IonCol, IonIcon, IonButton, IonSelect, IonSelectOption } from '@ionic/vue';
import { cart, trashOutline } from 'ionicons/icons';
import QuantityCounter from './Components/QuantityCounter.vue'
// import Coin from '@/components/elements/toys/CoinIcon.vue'

import store from '@/store/index';
import router from '@/router/index';
import axios from 'axios';

import MainHeader from '@/views/Admin/Components/MainHeader.vue'

  const segmentChanged = () => {
    console.log('segment changed')
  }

  const en = computed(() => s.lang == 'en')

  const switchLang = (en, ar) => {
    if(s.lang == 'en')
    { return en } 
    else if(s.lang == 'ar')
    { return ar }
  }

  const removeItem = (item) => {
    delete s.state.user.cart.find(i => i.id == item.id)
  }

  const total = () =>  Object.values(s.state.user.cart).reduce((ac,item) => ac + parseInt(item.item.price) * parseInt(item.quantity),0)

  const appliedCoupon = ref({}) 

  const applyCoupon = (coupon) => {
    if(coupon.id == appliedCoupon.value.id){
      appliedCoupon.value = { id: 0, code: '0', value: 0, type: '0', descriptionEn: '0', descriptionAr: '0', image: '0'}
    } else {
      appliedCoupon.value = coupon
    }
  }

  const coinsUsed = ref(1000)

  // const finalTotal = computed(() => {
  //   let finalTotal = total.value

  //   if(appliedCoupon.value.id !== 0){
  //     if(appliedCoupon.value.type == 'bogo'){
  //       s.state.user.cart.map((cartItem) => {
  //         // cartItem.quantity = cartItem.quantity * 2
  //         finalTotal = total.value  
  //       })
  //     }else if(appliedCoupon.value.type == 'discountPercentage'){
  //       finalTotal = total.value - (total.value * (appliedCoupon.value.value /100)) 
  //     }else if(appliedCoupon.value.type == 'discountAmount'){
  //       finalTotal = total.value - appliedCoupon.value.value 
  //     }else{
  //       finalTotal = total.value 
  //     }
  //   }

  //   return finalTotal - (coinsUsed.value / 1000)
  // })

  // const pay = () => {
  //   store.state.user.orders.push({id: s.state.user.cart.length + 1, total: finalTotal.value, date: Date(), items: s.state.user.cart})
  //   localStorage.setItem('orders', JSON.stringify(store.state.user.orders))
  //   localStorage.setItem('cart', JSON.stringify(s.state.user.cart))
  //   router.push('/client/bank')
  // }

  const pay = () => {

    const formData = new FormData()

    formData.append('products', JSON.stringify(s.state.user.cart))

    axios({
        method: 'post',
        url:`${store.state.api}orders`,
        data: formData,
        headers: {
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
        'Content-Type': 'application/x-www-form-urlencoded'
        // 'Content-Type': 'multipart/form-data'
        }
    }).then( () => {
        store.state.toastMessage = 'Order Placed'
        store.state.toastColor = 'success'
        store.dispatch('openToast')
        store.dispatch('getClientOrders')


        router.push('/')

    }).catch((errors) => {
        store.state.toastMessage = errors.response
        store.state.toastColor = 'danger'
        store.dispatch('openToast')
    })

    }

</script>

<style scoped>

.btn:active {
    transform: scale(0.9)
}

</style>